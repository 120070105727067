export default (clientHttp, apiVersion) => ({
  /**
   *
   * @param {String | Number} id
   * @param {{
   *  page?: Number,
   *  private_token?: String,
   *  start_date?: String | null,
   *  end_date?: String | null }} params
   * @returns {PromiseLike<{ data: import('~/types/booking/types').Booking[]; meta: { next_page: number | null; current_page: number }}>}
   */
  read(id, params) {
    return clientHttp.get(`${apiVersion}/houses/${id}/bookings`, {
      params,
    })
  },
})
