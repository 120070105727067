<script setup lang="ts">
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'
import type { DropUpKey } from '~/composables/Header/useHeaderMobile'

const { t } = useI18n()
const localePath = useLocalePath()
const config = useRuntimeConfig()
const route = useRoute()
const { trackEvent } = useTracking()
const browserLanguage = useState<string>('browserLanguage')
const { headerMobile, setMenuIsOpen, setActiveDropUp, setShowDropUp } =
  useHeaderMobile()

const tracking = (key: NonNullable<DropUpKey>) => {
  const mappingTracking = {
    contact: 'contact',
    language: 'language-currency',
  } as const
  trackEvent({
    event: 'header_clicked',
    item: mappingTracking[key],
  })
}

const phoneNumber = computed(() =>
  phoneByLanguage({
    config,
    language: browserLanguage.value,
  }),
)

const bottomNav = computed(
  () =>
    [
      {
        ariaLabel: t('header.contact'),
        class: {
          'active-marker':
            route.name?.toString().startsWith('contact') && !dropUpActive.value,
        },
        key: 'contact',
        iconName: 'mail',
        href: localePath('contact'),
        variant: 'link',
        onClick: () => {
          tracking('contact')
          setShowDropUp(false)
          setMenuIsOpen(false)
        },
      },
      {
        ariaLabel: t('header.phone'),
        href: `tel:${phoneNumber.value}`,
        iconName: 'phone',
        key: null,
        variant: 'link',
        onClick: () => {
          setShowDropUp(false)
          trackEvent({
            event: 'phone_number_click',
            label: { section: 'mobile_bottomnav' },
            source: 'header',
          })
        },
      },
      {
        ariaLabel: t('header.language'),
        class: { 'active-marker': dropUpActive.value === 'language' },
        key: 'language',
        iconName: 'language',
        variant: 'btn',
        onClick: () => {
          setActiveDropUp('language')
          tracking('language')
        },
      },
    ] as const,
)

const dropUpActive = computed(() => headerMobile.value.dropUpActive)
const showDropUp = computed(() => headerMobile.value.showDropUp)
const menuIsOpen = computed(() => headerMobile.value.menuIsOpen)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)
const slidePageActive = computed(() => headerMobile.value.slidePageActive)

const setColorIcon = (key: DropUpKey) =>
  !showDropUp.value || (showDropUp.value && key === dropUpActive.value)
    ? 'text-gray-700'
    : 'text-gray-400'
</script>

<template>
  <nav
    :class="[
      'bottom-nav flex w-full items-center justify-between border-t border-gray-200 bg-white px-16 py-6 tablet:px-36',
      { show: menuIsOpen && !slidePageActive && !searchIsOpen },
    ]"
  >
    <BaseButton
      v-for="link in bottomNav"
      v-bind="link"
      :key="link.ariaLabel"
      color="black"
      data-testid="navigation-links-mobile-bottomnav"
      no-padding
      @click="link.onClick"
    >
      <BaseIcon
        :class="setColorIcon(link.key)"
        :name="link.iconName"
        data-testid="navigation-icons-mobile-bottomnav"
      />
    </BaseButton>
  </nav>
</template>

<style scoped>
.bottom-nav {
  box-shadow: 1px 1px 6px 0 rgba(202, 202, 202, 0.5);
}
.bottom-nav > .active-marker {
  position: relative;
}
.bottom-nav > .active-marker:after {
  content: '';
  height: 2px;
  top: 110%;
  @apply absolute left-0 right-0 mx-0 my-auto bg-gray-700;
}
.bottom-nav {
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  transform: translate3d(0, 100%, 0);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  transition: opacity 0;
}
.bottom-nav.show {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
}
</style>
