import { SearchHousesAndDestinationsRepository } from "./interfaces/SearchHousesAndDestinationsRepository";
import { SearchHousesAndDestinationsPresentation } from "./interfaces/SearchHousesAndDestinationsPresentation";
import {
  AttributesToRetrieve,
  QueryParamsType,
  Locale,
  Mode,
  SearchResponseType,
} from "../../../../types";
import { SearchType } from "../../../../services/algolia";

export default class SearchHousesAndDestinationsUseCase {
  constructor(private repository: SearchHousesAndDestinationsRepository) {}

  async execute(
    presenter: SearchHousesAndDestinationsPresentation,
    value: string,
    locale: Locale,
    mode: Mode,
    algoliaIds: SearchType
  ) {
    const repository = this.repository.algolia(algoliaIds);

    const indexPrefix = mode === "client" ? "public" : mode;
    const attributesToRetrieveHouse: AttributesToRetrieve = [
      "first_photo_url",
      "id",
      "name",
      "slug",
    ];
    if (mode !== "client") attributesToRetrieveHouse.push("private_token");

    const filters =
      mode === "client"
        ? "NOT houses_count=0 AND NOT state:hidden"
        : "NOT state:offboarded AND NOT state:on_hold AND NOT state:onboarding AND NOT houses_count=0";

    const queries: QueryParamsType[] = [
      {
        indexName: "public_destinations",
        query: value,
        params: {
          filters,
          restrictSearchableAttributes: [
            `name.${locale}`,
            `name_suffixes.${locale}`,
            "searchable_names",
          ],
          attributesToRetrieve: ["id", "clusterized_name", "first_photo_url"],
          clickAnalytics: true,
        },
      },
      {
        indexName: `${indexPrefix}_houses_is_pinned_house_desc`,
        query: value,
        params: {
          filters,
          attributesToRetrieve: attributesToRetrieveHouse,
          clickAnalytics: true,
        },
      },
    ];

    await repository.multiQuery(queries).then((res) => {
      const resp = res as unknown as SearchResponseType;
      const destinationsResult = resp[0].hits.map((hit) => ({
        first_photo_url: hit.first_photo_url,
        id: hit.id,
        name: hit.clusterized_name[locale],
      }));
      const housesResult = resp[1].hits;
      const hasResult =
        destinationsResult.length > 0 || housesResult.length > 0;

      if (value && hasResult) {
        presenter.displayDestinationsResult(destinationsResult);
        presenter.displayHousesResult(housesResult);
      } else {
        presenter.displayDestinationsResult([]);
        presenter.displayHousesResult([]);
        presenter.displayMessage("search.emptyList");
      }
    });
  }
}
