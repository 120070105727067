import { GetHousePhotosRepository } from "./interfaces/GetHousePhotosRepository";
import { GetHousePhotosPresentation } from "./interfaces/GetHousePhotosPresentation";
import { HousePhotos } from "../../domain/HousePhotos";
import type { FilterType } from "lc-repository/dist/services";

export default class GetHousePhotosUseCase {
  constructor(private repository: GetHousePhotosRepository) {}

  async execute(
    presenter: GetHousePhotosPresentation,
    houseId: string,
    pagination: { page: string; per: string },
    privateToken?: string,
    filter?: FilterType
  ) {
    try {
      const housePhotosResponse = await this.repository.getPhotosByHouseId({
        filter,
        houseId,
        pagination,
        privateToken,
      });

      if (housePhotosResponse?.data && housePhotosResponse?.meta) {
        const housePhotosFormatted = new HousePhotos({
          data: housePhotosResponse.data,
          meta: housePhotosResponse.meta,
        }).build();
        presenter.displayHousePhotos(housePhotosFormatted);
      } else {
        presenter.displayHousePhotos(null);
      }
    } catch (err) {
      console.log("GetHousePhotosUseCase", err);
    }
  }
}
