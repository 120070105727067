<script setup lang="ts">
import type { ExperimentKey } from '~/types/amplitude/types'

const show = ref(false)
const config = useRuntimeConfig()
const { experiments, changeVariant, variant, tabx } = useExperiment()
const testExperiment = 'test' as ExperimentKey
const debugExperiment = computed(() =>
  config.public.nodeEnv === 'development' &&
  !experiments.value.includes(testExperiment)
    ? [testExperiment]
    : [],
)
</script>

<template>
  <div
    :class="[
      'fixed bottom-2 right-2 z-[9999] max-h-[80vh] w-80 overflow-auto rounded-[12px] border border-gray-200 bg-white text-sm shadow-md',
      {
        hidden: !show,
      },
    ]"
  >
    <div class="px-4 pb-2">
      <div
        class="mb-2 flex w-full items-center justify-between pr-2 pt-4 text-base font-bold text-gray-700"
      >
        <p class="m-0">Experiments<span class="ml-2">🧬</span></p>
        <button type="button" @click="show = false">
          <BaseIcon name="close" />
        </button>
      </div>
      <div
        v-for="exp in experiments.concat(debugExperiment)"
        :key="exp"
        :class="[
          'mb-2 flex items-center justify-between gap-8 border-b border-gray-200 pb-2 text-md last:border-b-0',
          {
            'bg-gray-200 pt-2': String(exp) === 'test',
          },
        ]"
      >
        <div class="pl-2">
          <p class="mb-0">
            <span class="font-semibold text-gray-700">Key:</span>
            &nbsp;<span>{{ exp }}</span>
          </p>
          <div class="mb-0">
            <span class="font-semibold text-gray-700">Variants:</span>&nbsp;
            <span
              v-for="(version, i) in ['control', 'treatment']"
              :key="version"
              :class="{
                'font-bold text-primary-700': variant(exp) === version,
              }"
            >
              {{ version }}{{ i === 0 ? ', ' : '' }}
            </span>
          </div>
          <div
            v-if="exp.toString() === 'test'"
            class="flex gap-2 font-semibold text-gray-700"
          >
            <p class="mb-0">Test translation:</p>
            <blockquote>{{ tabx('test.debug') }}</blockquote>
          </div>
        </div>

        <BaseTooltip class="block" position="left" rounded show size="small">
          <template #element>
            <button
              class="mr-2 size-6"
              type="button"
              @click="changeVariant(exp)"
            >
              <BaseIcon
                :size="2"
                :name="variant(exp) === 'control' ? 'switchOff' : 'switchOn'"
              />
            </button>
          </template>
          <template #text>
            Change to
            {{ variant(exp) === 'control' ? 'treatment' : 'control' }}
          </template>
        </BaseTooltip>
      </div>
    </div>
  </div>
  <div v-if="!show">
    <button
      type="button"
      class="fixed bottom-2 right-2 z-[9999] cursor-pointer rounded-full border border-gray-200 bg-white px-3 py-2 text-sm font-semibold shadow-md"
      @click="show = true"
    >
      <span>Experiments</span> <span class="ml-1">🧬</span>
    </button>
  </div>
</template>
