import { algolia } from 'lc-services/services/algolia'
import type { SearchResponse } from '@algolia/client-search'
import type { IndexName } from 'lc-services/types'

export const useSearchInputData = ({
  indexDestinations,
  indexHouses,
  locale,
}: {
  indexDestinations: Ref<IndexName>
  indexHouses: Ref<IndexName>
  locale: Ref<string>
}) => {
  const { algoliaIds } = useAuth()
  const client = algolia(algoliaIds.value)

  const searchQueryOnAlgoliaData = async ({
    filtersDesti = '',
    filtersHouse = '',
    hitsPerPage,
    query = '',
  }: {
    filtersDesti?: string
    filtersHouse?: string
    hitsPerPage: number
    query?: string
  }) => {
    const results = await client.multiQuery([
      {
        indexName: indexHouses.value,
        query,
        params: {
          filters: filtersHouse,
          hitsPerPage,
        },
      },
      {
        indexName: indexDestinations.value,
        query,
        params: {
          filters: filtersDesti,
          restrictSearchableAttributes: [
            `name.${locale.value}`,
            `name_suffixes.${locale.value}`,
            'searchable_names',
          ],
          hitsPerPage,
        },
      },
    ])

    return results || []
  }

  return { searchQueryOnAlgoliaData }
}

export type { SearchResponse as AlgoliaSearchResponse }
