// API V2
import adminChangeRequests from '~/repository/services/v2/admin/changeRequests'
import businessContactInquiries from '~/repository/services/v2/businessContactInquiries'
import contract from '~/repository/services/v2/contract'
import house from '~/repository/services/v2/house'
import inboundHouseRequests from '~/repository/services/v2/inboundHouseRequests'
import photos from '~/repository/services/v2/photos'
import review from '~/repository/services/v2/review'
import userClientContracts from '~/repository/services/v2/user/clientContracts'
import userClientHouses from '~/repository/services/v2/user/clientHouses'
import userContractInformations from '~/repository/services/v2/user/contractInformations'
import userContractsHouses from '~/repository/services/v2/user/contractsHouses'
import userHousesBookings from '~/repository/services/v2/user/houses/bookings'
import userHousesContract from '~/repository/services/v2/user/houses/contract'
import userHousesContracts from '~/repository/services/v2/user/houses/contracts'
import userSignatoryHouses from '~/repository/services/v2/user/signatoryHouses'
import userWaitingSearches from '~/repository/services/v2/user/waitingSearches'

export default {
  v2: {
    adminChangeRequests,
    businessContactInquiries,
    contract,
    house,
    inboundHouseRequests,
    photos,
    review,
    userClientContracts,
    userClientHouses,
    userContractInformations,
    userContractsHouses,
    userHousesBookings,
    userHousesContract,
    userHousesContracts,
    userSignatoryHouses,
    userWaitingSearches,
  },
}
