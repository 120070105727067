import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'

import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
// the esm import of the locale does not seem to work server-side
// it always fallbacks to the default 'en' locale
if (import.meta.server) dayjs.locale(fr)

export const $dayjs = dayjs

export const setDayjsLocale = (locale: MaybeRef<string>) => {
  const value = unref(locale)
  if ($dayjs.locale() !== value) $dayjs.locale(value)
}
