import type { HouseSlug, Locale } from 'lc-services/types'

export const hash = async (string: string) => {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}

export const createQueryParams = (
  obj: Record<string, string | number | boolean | undefined | null>,
) => {
  return Object.entries(obj)
    .reduce((params, [key, value]) => {
      if (value !== undefined && value !== null) {
        params.append(key, value.toString())
      }
      return params
    }, new URLSearchParams())
    .toString()
}

/** Makes a (localized) string suitable for an HTML id (no space, no uppercase, no #, no _) */
export const hashify = (
  value?: HouseSlug | string | null,
  locale: MaybeRef<Locale> = 'en',
) =>
  !value
    ? ''
    : (typeof value === 'string' ? value : value[unref(locale)])
        .replace(/[\s_]+/g, '-')
        .replace(/[#[\]()&]/g, '')
        .toLowerCase()
