import { User } from "lc-repository/dist/entities";
import { LoginWithProvidersPresentation } from "../usecases/LoginWithProviders/interfaces/LoginWithProvidersPresenter";
import { LoginWithProvidersRepository } from "../usecases/LoginWithProviders/interfaces/LoginWithProvidersRepository";
import {
  LoginWithProvidersProps,
  LoginWithProvidersWebProps,
} from "../usecases/LoginWithProviders/types";
import {
  MobileRepositoryHandlerAsync,
  MobileSuccessHandler,
  ErrorsHandler,
  WebSuccessHandler,
} from "./types";

export class LoginAddon {
  constructor(private repository: LoginWithProvidersRepository) {}

  private async repositoryHandlerAsync({
    email,
    uuid,
    provider,
  }: MobileRepositoryHandlerAsync) {
    const response = await this.repository.signIn({
      user: { email, uuid, provider },
    });

    const user = response.data
      ? (response.data as unknown as User<{ serialized: true }>)
      : null;

    const errors = response.errors
      ? (response.errors as unknown as { status: number })
      : null;

    return { user, errors };
  }

  private mobileSuccessHandler({
    presenter,
    user,
    firstLaunch,
    redirect,
  }: MobileSuccessHandler) {
    if (user) {
      if (user.incompleteSignup === true) {
        presenter.displayNavigation("SignUp");
      } else if (firstLaunch)
        presenter.displayNavigation("EnableNotifications");
      else presenter.displayNavigation(redirect || "App");
    }
  }

  private webSuccessHandler({ presenter, user }: WebSuccessHandler) {
    if (user) {
      if (user.incompleteSignup === true) presenter.displayNavigation("SignUp");
      else presenter.displayNavigation("App");
    }
  }

  private errorsHandler({ presenter, errors }: ErrorsHandler) {
    if (errors?.status === 422) presenter.displayNavigation("SignUp");
    if (
      (errors?.response?.data?.errors?.[0] as { user: string })?.user ===
      "incomplete_signup"
    )
      presenter.displayNavigation("SignUp");
    else if (errors?.status === 404 || errors?.status === 400)
      presenter.displayToast({
        type: "error",
        msg: "tierPartConnect.accountNotFound",
      });
  }

  protected async loginOnMobileAppAsync(
    presenter: LoginWithProvidersPresentation,
    mobileProps: LoginWithProvidersProps
  ) {
    const { email, uuid, provider, firstLaunch, redirect } = mobileProps;

    const { user, errors } = await this.repositoryHandlerAsync({
      email,
      uuid,
      provider,
    });

    this.mobileSuccessHandler({ presenter, user, firstLaunch, redirect });
    this.errorsHandler({
      presenter,
      errors: errors as ErrorsHandler["errors"],
    });
  }

  protected async loginOnWebAsync(
    presenter: LoginWithProvidersPresentation,
    webProps: LoginWithProvidersWebProps
  ) {
    const { email, uuid, provider } = webProps;
    const { user, errors } = await this.repositoryHandlerAsync({
      email,
      uuid,
      provider,
    });

    this.webSuccessHandler({ presenter, user });
    this.errorsHandler({
      presenter,
      errors: errors as ErrorsHandler["errors"],
    });
  }
}
