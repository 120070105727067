import {
  SignInWithEmailUseCase,
  RemoveUserSessionUseCase,
  SignUpUseCase,
  TokenAuthUseCase,
  LoginWithProvidersUseCase,
} from "../usecases";
import { SignInWithEmailPresentation } from "../usecases/SignInWithEmail/interfaces/SignInWithEmailPresentation";
import { TokenAuthPresentation } from "../usecases/TokenAuth/interfaces/TokenAuthPresentation";
import { SignUpPresentation } from "../usecases/SignUp/interfaces/SignUpPresentation";
import { SignInWithInput } from "../usecases/SignInWithEmail/types";
import { SignInTokenInput } from "../usecases/TokenAuth/types";
import { CreateUserInput } from "../usecases/SignUp/types";
import { RemoveUserSessionPresentation } from "../usecases/RemoveUserSession/interfaces/RemoveUserSessionPresentation";
import { UserType } from "../../../types";
import {
  LoginWithProvidersProps,
  LoginWithProvidersWebProps,
} from "../usecases/LoginWithProviders/types";
import { LoginWithProvidersPresentation } from "../usecases/LoginWithProviders/interfaces/LoginWithProvidersPresenter";

type Usecases = {
  signInWithEmail: SignInWithEmailUseCase;
  removeUserSession: RemoveUserSessionUseCase;
  signUp: SignUpUseCase;
  tokenAuth: TokenAuthUseCase;
  loginWithProviders: LoginWithProvidersUseCase;
};

type Presenter = SignInWithEmailPresentation &
  RemoveUserSessionPresentation &
  SignUpPresentation &
  TokenAuthPresentation &
  LoginWithProvidersPresentation;

export default class AuthController {
  constructor(
    private usecases: Partial<Usecases>,
    private presenter: Presenter
  ) {}

  async signOut() {
    await this.usecases.removeUserSession?.execute(this.presenter);
  }

  async sendTokenByEmail(input: SignInWithInput) {
    await this.usecases.signInWithEmail?.execute(this.presenter, input);
  }

  async verifyToken(input: SignInTokenInput) {
    await this.usecases.tokenAuth?.execute(this.presenter, input);
  }

  async signUp(input: CreateUserInput, validateTokenResponse?: UserType) {
    await this.usecases.signUp?.execute(
      this.presenter,
      input,
      validateTokenResponse
    );
  }

  async loginWithProviders({
    mobileProps,
    webProps,
  }: {
    mobileProps?: LoginWithProvidersProps;
    webProps?: LoginWithProvidersWebProps;
  }) {
    await this.usecases.loginWithProviders?.execute({
      presenter: this.presenter,
      mobileProps,
      webProps,
    });
  }
}
