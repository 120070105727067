<script setup lang="ts">
defineEmits<{ 'is-phone-valid': [boolean] }>()
defineSlots<{ default(): any }>()

const formValues = defineModel<{
  firstName: string
  lastName: string
  email: string
  formFields: {
    mobilephone: string
    message: string
  }
}>({ required: true })

const { t } = useI18n()
</script>

<template>
  <div class="flex w-full flex-col justify-between overflow-y-scroll px-1">
    <p class="font-bold">{{ t('title') }}</p>

    <div class="flex flex-wrap gap-4 sm:justify-between">
      <BaseFormComponentsBaseInput
        v-model="formValues.firstName"
        name="firstName"
        rules="required"
        wrapper-class="w-full sm:w-[48%]"
        :label="$t('field.firstName')"
      />
      <BaseFormComponentsBaseInput
        v-model="formValues.lastName"
        name="lastName"
        rules="required"
        wrapper-class="w-full sm:w-[48%]"
        :label="$t('field.lastName')"
      />
      <BaseFormComponentsBaseInput
        v-model="formValues.email"
        name="email"
        rules="required"
        type="email"
        wrapper-class="w-full sm:w-[48%]"
        :label="t('email')"
      />
      <BaseFormComponentsBaseTelInput
        v-model="formValues.formFields.mobilephone"
        name="mobilephone"
        rules="required"
        wrapper-class="w-full sm:w-[48%]"
        :label="t('phone')"
        @is-phone-valid="$emit('is-phone-valid', $event)"
      />
      <BaseFormComponentsBaseTextarea
        v-model="formValues.formFields.message"
        class="w-full !border-gray-200"
        name="message"
        rows="3"
        rules="required"
        :label="t('subject')"
      />
      <div class="flex w-full items-center justify-between">
        <slot />
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
fr:
  title: Vos informations
  email: Votre adresse email
  phone: Numéro de téléphone
  subject: Sujet de l'appel
en:
  title: Your informations
  email: Your email address
  phone: Phone number
  subject: Subject of the call
</i18n>
