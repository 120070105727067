import { buildRepositories } from "lc-repository";
import Controller from "./adapters/Controller";
import Presenter from "./adapters/Presenter";
import {
  GetAreaEquipmentsUseCase,
  GetDestinationFavoritePeriodUseCase,
  GetHouseDetailsUseCase,
  GetHouseIncludedServices,
  GetHouseTruthLinksUseCase,
  GetHouseKeyFeaturesUseCase,
  GetHousePeriodsAndBookingsUseCase,
  GetHousePeriodsByIdUseCase,
  GetHousePeriodsUseCase,
  GetHousePhotosUseCase,
  GetNbHiddenHousesUseCase,
  GetTopDestinationsUseCase,
  MakeHouseRequestUseCase,
  OrderRetrievedFacetsUseCase,
  SearchHousesAndDestinationsUseCase,
  SearchHousesMapUseCase,
  SearchHousesWithDatesUseCase,
  SearchHousesWithoutDatesUseCase,
  SearchHousesWithoutDatesV2UseCase,
  UpdateInquiryUseCase,
  GetDestinationPhotoUseCase,
  GetSimilarHousesPricesByPeriodUseCase,
  SearchDestinationsUseCase,
} from "./usecases";

import { algolia } from "../../services/algolia";

export type SearchBuildType = {
  repository: ReturnType<typeof buildRepositories.lc>;
};

export const searchBuild = ({ repository }: SearchBuildType) => {
  const { houses, request } = repository;
  const commonRepo = {
    algolia,
    getHousePeriodsIds: houses.periods.getHousePeriodsIds,
    getHousePeriods: houses.periods.getHousePeriods,
  };
  const presenter = new Presenter();
  const controller = new Controller(
    {
      getHouseDetails: new GetHouseDetailsUseCase(houses),
      getHouseAreaEquipments: new GetAreaEquipmentsUseCase(houses),
      getHouseKeyFeatures: new GetHouseKeyFeaturesUseCase(houses),
      getHouseIncludedServices: new GetHouseIncludedServices({
        getHouseIncludedServices: houses.getHouseIncludedServices,
      }),
      getHouseTruthLinks: new GetHouseTruthLinksUseCase({
        getHouseTruthLinks: houses.getHouseTruthLinks,
      }),
      getHousePeriods: new GetHousePeriodsUseCase({
        getHousePeriods: houses.periods.getHousePeriods,
      }),
      getHousePeriodsById: new GetHousePeriodsByIdUseCase({
        getHousePeriodsById: houses.periods.getHousePeriodsById,
      }),
      getHousePhotos: new GetHousePhotosUseCase(houses),
      getHousePeriodsAndBookings: new GetHousePeriodsAndBookingsUseCase({
        getBookingsByHouseId: houses.bookings.getBookingsByHouseId,
        getPeriodDurations: houses.periods.getPeriodDurations,
      }),
      getTopDestinations: new GetTopDestinationsUseCase(commonRepo),
      makeHouseRequest: new MakeHouseRequestUseCase(request.request),
      orderFacets: new OrderRetrievedFacetsUseCase(),
      searchHouses: {
        searchHousesWithoutDates: new SearchHousesWithoutDatesUseCase(
          commonRepo
        ),
        searchHousesWithDates: new SearchHousesWithDatesUseCase(commonRepo),
      },
      searchHousesV2: {
        searchHousesWithoutDates: new SearchHousesWithoutDatesV2UseCase(
          commonRepo
        ),
        searchHousesWithDates: new SearchHousesWithDatesUseCase(commonRepo),
      },
      searchHousesAndDestinations: new SearchHousesAndDestinationsUseCase(
        commonRepo
      ),
      updateInquiry: new UpdateInquiryUseCase(request.request),
      getNbHiddenHouses: new GetNbHiddenHousesUseCase(commonRepo),
      searchHousesMap: new SearchHousesMapUseCase(commonRepo),
      getDestinationFavoritePeriod: new GetDestinationFavoritePeriodUseCase({
        getCountByPeriodTypes: houses.periods.getCountByPeriodTypes,
      }),
      getDestinationPhoto: new GetDestinationPhotoUseCase(houses),
      getSimilarHousesPricesByPeriod: new GetSimilarHousesPricesByPeriodUseCase(
        {
          getHousePeriodsById: houses.periods.getHousePeriodsById,
        }
      ),
      searchDestinations: new SearchDestinationsUseCase({
        algolia: commonRepo.algolia,
      }),
    },
    presenter
  );

  return { controller, presenter };
};
