<script setup lang="ts">
import { useSearchInputData } from '~/composables/useSearchInputData'
import type {
  Locale,
  MutilpleIndicesGenericType,
  SearchHouse,
  SearchResponseType,
} from 'lc-services/types'

const emits = defineEmits<{
  'has-query': [boolean, string] | [boolean]
  houses: [ReturnType<typeof serializeResultHouse>]
  destinations: [ReturnType<typeof serializeResultDestinations>]
}>()

const { locale } = useI18n()
const { userIsAdmin } = useAuth()
const localePath = useLocalePath()
const { isDesktop } = useBreakpoint()
const { trackEvent } = useTracking()

const query = ref('')
const searchInput = ref<HTMLInputElement>()
const isResultEmpty = ref(false)

const hasQuery = computed(() => query.value.length >= 2)

watch(query, (val, oldVal) => {
  if (isResultEmpty.value && new RegExp(oldVal).test(val))
    return emits('has-query', false)
  isResultEmpty.value = false
  if (hasQuery.value) {
    searchQueryOnAlgolia(val)
    emits('has-query', true, val)
  } else {
    emits('has-query', false)
  }
})

onMounted(() => {
  if (isDesktop.value) {
    // Wait before putting the foucus (fouc)
    setTimeout(() => {
      searchInput.value?.focus()
    }, 200)
  } else {
    searchInput.value?.focus()
  }
})

const searchQueryOnAlgolia = async (query: string) => {
  let filtersHouse = 'NOT houses_count=0 AND NOT state:hidden'

  if (userIsAdmin.value) {
    filtersHouse =
      'NOT state:offboarded AND NOT state:on_hold AND NOT state:onboarding AND NOT houses_count=0'
  }

  const indexDestinations = ref('public_destinations' as const)
  const indexHouses = ref('public_houses_is_pinned_house_desc' as const)

  const results = await useSearchInputData({
    indexDestinations,
    indexHouses,
    locale,
  }).searchQueryOnAlgoliaData({
    filtersDesti: filtersHouse,
    filtersHouse,
    hitsPerPage: 3,
    query,
  })

  const houses = results.length
    ? (results as unknown as SearchResponseType)[0]?.hits
    : []
  const destinations = results.length
    ? (results as unknown as SearchResponseType)[1]?.hits
    : []

  isResultEmpty.value = !houses.length && !destinations.length
  if (isResultEmpty.value)
    trackEvent({
      event: 'search_without_suggestions',
      search_query: query,
    })

  emits('houses', serializeResultHouse(houses as unknown as SearchHouse[]))
  emits('destinations', serializeResultDestinations(destinations))
}
const serializeResultHouse = (houses: SearchHouse[]) =>
  houses
    .filter((h) => h.destination?.fr)
    .map((house) => {
      const [firstDestination] =
        house.destination[locale.value as Locale].split(',')

      return {
        id: house.id,
        image: house.first_photo_url,
        href: localePath({
          name: 'house-slug',
          params: { slug: house.slug[locale.value as Locale] },
        }),
        name: house.name,
        subName: firstDestination,
      }
    })
const serializeResultDestinations = (
  destinations: (MutilpleIndicesGenericType & {
    first_photo_url?: string | null
  })[],
) => {
  return destinations.map((desti) => {
    const [name, parentDesti] =
      desti.clusterized_name[locale.value as Locale].split(',')

    return {
      image: desti.first_photo_url,
      href: localePath({
        name: 'search',
        query: { destinationId: desti.id },
      }),
      name,
      subName: parentDesti,
      search: {
        name: desti.name,
        destinationId: desti.id,
      },
    }
  })
}
</script>

<template>
  <div class="relative mb-6 laptop:mb-14">
    <input
      ref="searchInput"
      v-model="query"
      class="w-full rounded-full bg-gray-100 px-6 py-2.5 focus:border-primary-200 focus:shadow-focus focus:outline-none laptop:py-4"
      :placeholder="$t('header.search')"
    />

    <button
      v-show="hasQuery"
      class="search-close absolute right-6 top-3.5 text-gray-700 focus:outline-none laptop:right-7 laptop:top-5"
      data-testid="the-header-search-clear-input"
      type="button"
      @click="query = ''"
    >
      <BaseIcon name="cancel" />
    </button>
  </div>
</template>

<style scoped>
.search-close span {
  display: block;
  font-weight: bold;
}
</style>
