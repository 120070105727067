export const transparentPagesNames = [
  'addedValue',
  'bonder',
  'customHolidays',
  'destination-name',
  'events',
  'feedback-id',
  'forgotPassword',
  'house-slug',
  'houseCollection',
  'index',
  'login',
  'myaccount-contractForm-clientId',
  'myaccount-contractForm-ownerId',
  'owners',
  'realEstate',
  'tailor',
  'tgv',
  'the-greek-villas',
  'travelPartners',
]
