import { algoliasearch } from "algoliasearch";

import { QueryParamsType } from "../types";

export type SearchType = {
  appId: string;
  apiKey: string;
  prefix: "staging" | "production";
};

export type AlgoliaReturnType = (
  input: SearchType
) => ReturnType<typeof algolia>;

export type AlogliaType = ReturnType<typeof algolia>;

export const algolia = ({ appId, apiKey, prefix }: SearchType) => {
  const client = algoliasearch(appId, apiKey);

  const multipleIndices = <T>(queries: QueryParamsType[]) =>
    client.search<T>({
      requests: queries.map((query) => ({
        ...query.params,
        query: query.query,
        indexName: `${prefix}_${query.indexName}`,
      })),
    });

  return {
    multiQuery: async <T>(queries: QueryParamsType[]) => {
      return multipleIndices<T>(queries).then((res) => res.results);
    },
    simpleQuery: <T>(query: QueryParamsType) => {
      return client.searchSingleIndex<T>({
        indexName: `${prefix}_${query.indexName}`,
        searchParams: {
          ...query.params,
          query: query.query,
          clickAnalytics: true,
          typoTolerance: false,
        },
      });
    },
    browseQuery: async <T>(query: QueryParamsType) => {
      let hits: T[] = [];

      await client.browseObjects<T>({
        browseParams: {
          ...query.params,
          clickAnalytics: true,
        },
        indexName: `${prefix}_${query.indexName}`,
        aggregator: (res) => {
          hits = hits.concat(res.hits);
        },
      });

      return hits;
    },
  };
};
