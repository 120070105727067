// See https://github.com/nuxt/nuxt/issues/23612 and https://github.com/nuxt/nuxt/issues/29624 for more context
export default defineNuxtPlugin((nuxtApp) => {
  // Remember `to.path` when navigating to a new path:
  //   A `chunkError` may occur during navigation,
  //   we then want to reload at `to.path`
  let currentlyNavigationTo: null | string = null
  addRouteMiddleware((to) => {
    currentlyNavigationTo = to.path
  })

  // Reload when a `chunkError` is thrown
  nuxtApp.hook('app:chunkError', () =>
    reloadNuxtApp({
      persistState: true,
      path: currentlyNavigationTo ?? nuxtApp._route.path,
    }),
  )

  // Reload when the app manifest updates
  nuxtApp.hook('app:manifest:update', () =>
    reloadNuxtApp({ persistState: true, path: nuxtApp._route.path }),
  )
})
