import { GetHouseIncludedServicesRepository } from "./interfaces/GetHouseIncludedServicesRepository";
import { GetHouseIncludedServicesPresentation } from "./interfaces/GetHouseIncludedServicesPresentation";
import { HouseIncludedServices } from "../../domain/HouseIncludedServices";

export default class GetHouseIncludedServicesUseCase {
  constructor(private repository: GetHouseIncludedServicesRepository) {}

  async execute(
    presenter: GetHouseIncludedServicesPresentation,
    input: { houseId: string | number; privateToken?: string }
  ) {
    const response = await this.repository.getHouseIncludedServices(input);

    if (response?.data) {
      const houseIncludedServices = new HouseIncludedServices(
        response.data
      ).build();
      presenter.displayHouseIncludedServices(houseIncludedServices);
    } else {
      presenter.displayHouseIncludedServices([]);
    }
  }
}
