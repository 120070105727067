import { ReplicasValues, IndexNameReplica, Mode } from "../../../../types";

const getPrefix = (mode: Mode) => {
  if (mode === "admin") return "admin";
  if (mode === "b2b2c") return "b2b2c";

  return "public";
};

export const formatReplicas = (value: ReplicasValues, mode: Mode) => {
  const prefix = getPrefix(mode);
  const formattedValues = {
    recommended: `${prefix}_houses_is_pinned_house_desc`,
    price_asc: `${prefix}_houses_price_asc`,
    price_desc: `${prefix}_houses_price_desc`,
    bedroom_asc: `${prefix}_houses_bedrooms_asc`,
    bedroom_desc: `${prefix}_houses_bedrooms_desc`,
    ...(mode === "admin"
      ? {
          commission_asc: `admin_houses_commission_rate_asc`,
          commission_desc: "admin_houses_commission_rate_desc",
        }
      : {}),
    ...(mode === "b2b2c"
      ? {
          commission_asc: `b2b2c_houses_commission_rate_asc`,
          commission_desc: "b2b2c_houses_commission_rate_desc",
        }
      : {}),
  };

  return formattedValues[value] as IndexNameReplica;
};
