import { LoginWithProvidersRepository } from "./interfaces/LoginWithProvidersRepository";
import { LoginWithProvidersPresentation } from "./interfaces/LoginWithProvidersPresenter";
import { LoginWithProvidersProps, LoginWithProvidersWebProps } from "./types";
import { LoginAddon } from "../../domain/LoginAddon";

type LoginWithProvidersUseCaseProps = {
  presenter: LoginWithProvidersPresentation;
  mobileProps?: LoginWithProvidersProps;
  webProps?: LoginWithProvidersWebProps;
};

export default class LoginWithProvidersUseCase extends LoginAddon {
  constructor(repository: LoginWithProvidersRepository) {
    super(repository);
  }

  async execute({
    presenter,
    mobileProps,
    webProps,
  }: LoginWithProvidersUseCaseProps) {
    presenter.displayInitialize();

    if (mobileProps) {
      await this.loginOnMobileAppAsync(presenter, mobileProps);
    }

    if (webProps) {
      await this.loginOnWebAsync(presenter, webProps);
    }
  }
}
