import { VerifyIdentityDocumentRepository } from "./interfaces/VerifyIdentityDocumentRepository";
import {
  IdentityDocumentInformation,
  VerifyIdentityDocumentPresentation,
} from "./interfaces/VerifyIdentityDocumentPresentation";
import { IdentityDocumentFile } from "../../../../types";

export default class VerifyIdentityDocumentUseCase {
  private identityDocumentInformation: IdentityDocumentInformation = {
    identityDocumentType: "passport",
    civility: null,
    firstname: null,
    lastname: null,
    birthdate: null,
    nationality: null,
    identityDocumentNumber: null,
    identityDocumentExpirationDate: null,
    identityDocumentFiles: [],
  };
  constructor(private repository: VerifyIdentityDocumentRepository) {}

  private resetIdentityDocumentInformation(
    identityDocumentType: "passport" | "identityCard"
  ) {
    this.identityDocumentInformation = {
      identityDocumentType,
      civility: null,
      firstname: null,
      lastname: null,
      birthdate: null,
      nationality: null,
      identityDocumentNumber: null,
      identityDocumentExpirationDate: null,
      identityDocumentFiles: [],
    };
  }

  private getCivilityByCivilityLetter(
    civilityLetter: string | null | undefined
  ) {
    switch (civilityLetter) {
      case "M":
        return "mr";
      case "F":
        return "mrs";
      default:
        return "unspecified";
    }
  }

  private displayDevDocumentInformation({
    identityDocumentType,
    pickedDocuments,
    presenter,
  }: {
    presenter: VerifyIdentityDocumentPresentation;
    identityDocumentType: "passport" | "identityCard";
    pickedDocuments: IdentityDocumentFile[];
  }) {
    presenter.displayIdentityDocumentInformation({
      identityDocumentType,
      civility: this.getCivilityByCivilityLetter("M"),
      firstname: "Jean Jacques",
      lastname: "Michel",
      birthdate: "1996-07-18",
      nationality: "FRA",
      identityDocumentNumber: "15DE76005",
      identityDocumentExpirationDate: "2028-07-02",
      identityDocumentFiles: pickedDocuments,
    });
  }

  private async getPassportInformation(
    pickedDocuments: IdentityDocumentFile[]
  ) {
    if (pickedDocuments.length !== 1) {
      throw new Error("Too many files for passport verification");
    }

    this.identityDocumentInformation.identityDocumentFiles = pickedDocuments;

    const passportDocument = pickedDocuments[0];

    if (!passportDocument) {
      throw new Error("Missing file for passport verification");
    }

    const result = await this.repository.verifyPassport(
      passportDocument.base64
    );

    result.data.forEach((passportInformation) => {
      if (passportInformation.given_names) {
        this.identityDocumentInformation.firstname ||=
          passportInformation.given_names.join(" ");
      }
      this.identityDocumentInformation.civility ||=
        this.getCivilityByCivilityLetter(passportInformation.gender);
      this.identityDocumentInformation.lastname ||= passportInformation.surname;
      this.identityDocumentInformation.birthdate ||=
        passportInformation.birth_date;
      this.identityDocumentInformation.nationality ||=
        passportInformation.country;
      this.identityDocumentInformation.identityDocumentNumber ||=
        passportInformation.id_number;
      this.identityDocumentInformation.identityDocumentExpirationDate ||=
        passportInformation.expiry_date;
    });
  }

  private async getIdentityCardInformation(
    pickedDocuments: IdentityDocumentFile[]
  ) {
    if (pickedDocuments.length < 2) {
      throw new Error("Missing front or back of identity card");
    }

    if (pickedDocuments.length > 2) {
      throw new Error("Too many files for identity card verification");
    }

    this.identityDocumentInformation.identityDocumentFiles = pickedDocuments;

    const identityCardResults = await Promise.all(
      pickedDocuments.map(async (document) =>
        this.repository.verifyIdentityCard(document.base64)
      )
    );

    identityCardResults.reverse().forEach((identityCardResult) => {
      identityCardResult.data.forEach((identityCardInformation) => {
        if (identityCardInformation.given_names) {
          this.identityDocumentInformation.firstname ||=
            identityCardInformation.given_names.join(" ");
        }
        if (!this.identityDocumentInformation.civility || this.identityDocumentInformation.civility === 'unspecified') {
          this.identityDocumentInformation.civility = 
            this.getCivilityByCivilityLetter(identityCardInformation.gender);
        }
        this.identityDocumentInformation.lastname ||=
          identityCardInformation.surname;
        this.identityDocumentInformation.birthdate ||=
          identityCardInformation.birth_date;
        this.identityDocumentInformation.nationality ||=
          identityCardInformation.nationality;
        this.identityDocumentInformation.identityDocumentNumber ||=
          identityCardInformation.document_number;
        this.identityDocumentInformation.identityDocumentExpirationDate ||=
          identityCardInformation.expiry_date;
      });
    });
  }

  async execute(
    presenter: VerifyIdentityDocumentPresentation,
    input: {
      pickedDocuments: IdentityDocumentFile[];
      documentType: "passport" | "identityCard";
      isDevMode: boolean;
    }
  ) {
    this.resetIdentityDocumentInformation(input.documentType);
    if (input.isDevMode) {
      this.displayDevDocumentInformation({
        presenter,
        identityDocumentType: input.documentType,
        pickedDocuments: input.pickedDocuments,
      });
      return;
    }

    if (input.pickedDocuments.length === 0) {
      throw new Error("pickedDocuments cannot be empty");
    }

    if (input.documentType === "passport") {
      await this.getPassportInformation(input.pickedDocuments);
    }

    if (input.documentType === "identityCard") {
      await this.getIdentityCardInformation(input.pickedDocuments);
    }

    presenter.displayIdentityDocumentInformation(
      this.identityDocumentInformation
    );
  }
}
