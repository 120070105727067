<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    lineColor?: string
    modelValue?: boolean
  }>(),
  {
    lineColor: 'black',
    modelValue: false,
  },
)
defineEmits<{
  'update:model-value': [boolean]
}>()
const lineCount = ref(4)

const color = computed(() =>
  props.lineColor === 'black' ? 'bg-gray-700' : `bg-${props.lineColor}`,
)
</script>

<template>
  <BaseButton
    :aria-label="$t('global.close')"
    color="white"
    data-testid="the-header-burger"
    size="xs"
    @click="$emit('update:model-value', !modelValue)"
  >
    <span :class="['header-burger', { 'header-burger--open': modelValue }]">
      <span v-for="line in lineCount" :key="line" :class="color" />
    </span>
  </BaseButton>
</template>

<style scoped>
.header-burger {
  @apply flex w-6 relative h-5 transition-all duration-500 ease-in-out cursor-pointer;
}

:deep(.header-burger span) {
  @apply block absolute h-px w-full rounded-sm left-0 transition-all duration-300 ease-in-out;
}

:deep(.header-burger span:nth-child(1)) {
  @apply top-0;
}

:deep(.header-burger span:nth-child(2)),
:deep(.header-burger span:nth-child(3)) {
  @apply top-1/2;
}

:deep(.header-burger span:nth-child(4)) {
  @apply top-full;
}

:deep(.header-burger.header-burger--open span:nth-child(1)) {
  @apply w-0 left-1/2 top-1/2;
}

:deep(.header-burger.header-burger--open span:nth-child(2)) {
  @apply transform rotate-45;
}

:deep(.header-burger.header-burger--open span:nth-child(3)) {
  @apply transform -rotate-45;
}

:deep(.header-burger.header-burger--open span:nth-child(4)) {
  @apply w-0 left-1/2 top-1/2;
}
</style>
