<script setup lang="ts">
const currentDate = new Date()
const currentMonth = currentDate.getMonth() + 1
const currentYear = currentDate.getFullYear()

const {
  public: { meetingSchedulerSlug: meetingSlug },
} = useRuntimeConfig()
const { locale } = useI18n()
const { user } = useUser()
const { isMobile } = useBreakpoint()
const {
  closeScheduler,
  requestedHouse,
  schedulerIsOpen,
  trackSchedulerSuccess,
} = useMeetingScheduler()

const monthOffset = ref(0)
const {
  availableDurations,
  availabilities,
  fetchAvailabilities,
  fetchMeeting,
  formValues,
  disabledDates,
  isValid,
  maxDate,
  mutation,
  onDateSelect,
  onTimeSelect,
  onTimezoneSelect,
  selectedDate,
} = useHubspotMeetingScheduler<{
  house_name?: string
  message: string
  mobilephone: string
}>({
  currentDate,
  customFormValues: {
    house_name: requestedHouse.value?.name,
    mobilephone: user.value.phone ?? '',
    message: '',
  },
  meetingSlug,
  monthOffset,
  userInfos: {
    email: user.value.email ?? '',
    firstName: user.value.firstName ?? '',
    lastName: user.value.lastName ?? '',
  },
})

const error = ref(false)
const step = ref(1)
const mutating = ref(false)
const isPhoneValid = ref(Boolean(user.value.phone))

const timeFormatter = computed(
  () =>
    new Intl.DateTimeFormat(locale.value, {
      timeStyle: 'short',
      timeZone: formValues.timezone,
    }),
)
const fullTimeText = computed(
  () =>
    `${new Intl.DateTimeFormat(locale.value, {
      dateStyle: isMobile.value ? 'long' : 'full',
      timeZone: formValues.timezone,
    }).format(
      formValues.startTime,
    )} ${timeFormatter.value.format(formValues.startTime)}`,
)

const handleDurationSelect = (duration?: number) => {
  if (duration) formValues.duration = duration
}
const handleTimeSelect = (time?: number) => {
  if (typeof time === 'number') {
    onTimeSelect(time)
    if (time > 0) step.value = 2
  }
}
const handlePageSelect = async (page: { month: number; year: number }) => {
  monthOffset.value = page.month + 12 * (page.year - currentYear) - currentMonth
  await fetchAvailabilities({ dedupe: true })
}

const handleBack = () => {
  if (step.value > 1) {
    changeStep(Math.ceil(step.value - 1))
  } else {
    closeScheduler()
  }
}
const handleRestart = () => {
  error.value = false
  changeStep(1)
}
const changeStep = (to: number) => {
  // reset the time to 0 when navigating back from mobile micro-step
  if (isMobile.value && step.value >= 1.1 && step.value > to) onTimeSelect(0)
  step.value = to
}
const submit = async () => {
  try {
    error.value = false
    mutating.value = true
    await mutation(formValues)
    trackSchedulerSuccess()
  } catch {
    error.value = true
  } finally {
    mutating.value = false
    step.value = 3
  }
}

watch(
  schedulerIsOpen,
  async (val) => {
    step.value = 1
    if (val)
      await Promise.all([fetchMeeting(), fetchAvailabilities({ dedupe: true })])
  },
  { immediate: import.meta.client },
)
watch(availableDurations, (newVal) => {
  if (newVal.length === 1) handleDurationSelect(newVal[0])
})
watch(
  requestedHouse,
  (house) => {
    if (house?.name) formValues.formFields.house_name = house.name
    else
      formValues.formFields = {
        message: formValues.formFields.message,
        mobilephone: formValues.formFields.mobilephone,
      }
  },
  { immediate: import.meta.client },
)
</script>

<template>
  <BaseModalV2
    size="m"
    has-close-button
    mobile-full-height
    :has-back-button="isMobile"
    :model-value="schedulerIsOpen"
    :no-margin="step === 1"
    @back="handleBack"
    @close="closeScheduler"
  >
    <template #content>
      <div class="flex h-full flex-col gap-6 overflow-hidden sm:h-[550px]">
        <TheMeetingSchedulerHeader
          :step="step"
          @change-step="changeStep($event)"
        >
          <p class="m-0 text-md sm:text-base">
            {{ fullTimeText }}
          </p>
        </TheMeetingSchedulerHeader>

        <TheMeetingSchedulerDateTimeStep
          v-if="Math.floor(step) === 1"
          :availabilities="availabilities"
          :available-durations="availableDurations"
          :disabled-dates="disabledDates"
          :duration="formValues.duration"
          :max-date="maxDate"
          :selected-date="selectedDate"
          :start-time="formValues.startTime"
          :step="step"
          :timezone="formValues.timezone"
          @change-page="handlePageSelect"
          @change-step="changeStep($event)"
          @update:duration="handleDurationSelect"
          @update:selected-date="onDateSelect"
          @update:start-time="handleTimeSelect"
          @update:timezone="onTimezoneSelect"
        />

        <TheMeetingSchedulerContactInformationStep
          v-else-if="step === 2"
          v-model="formValues"
          @is-phone-valid="isPhoneValid = $event"
        >
          <BaseButton
            v-if="!isMobile"
            class="!flex !items-center gap-2"
            color="secondary"
            variant="link"
            @click="step = 1"
          >
            <BaseIcon name="navArrowLeft" :size="1" />
            {{ $t('action.back') }}
          </BaseButton>
          <BaseButton
            class="!flex !items-center !justify-center gap-2"
            color="secondary"
            :block-full="isMobile"
            :disabled="!isValid || !isPhoneValid"
            :loader="mutating"
            @click="submit"
          >
            {{ $t('action.confirm') }}
          </BaseButton>
        </TheMeetingSchedulerContactInformationStep>

        <TheMeetingSchedulerConfirmationStep
          v-else-if="step === 3"
          :error="error"
          @restart="handleRestart"
        >
          <p class="m-0 font-bold">{{ fullTimeText }}</p>
        </TheMeetingSchedulerConfirmationStep>
      </div>
    </template>
  </BaseModalV2>
</template>
