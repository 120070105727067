<script setup lang="ts">
import type { Rules } from '../types'

const { rules = '' } = defineProps<{
  rules?: Rules
}>()

const isRequired = computed(() =>
  typeof rules === 'string' ? rules.includes('required') : rules.required,
)
</script>

<template>
  <span v-if="isRequired" data-testid="base-form-label-required" class="ml-1">
    *
  </span>
</template>
