export type StoredData<T> = {
  data: T
  timestamp: number
}

const getItemIsomorphic = <T>(storageKey: string, defaultValue: unknown) => {
  if (import.meta.server || typeof localStorage?.getItem !== 'function')
    return defaultValue as T

  const storedValue = localStorage.getItem(STORAGE_PREFIX.concat(storageKey))
  return (storedValue ? JSON.parse(storedValue) : defaultValue) as T
}
const setItemIsomorphic = (storageKey: string, value: unknown) => {
  if (import.meta.client && typeof localStorage?.setItem === 'function')
    localStorage.setItem(
      STORAGE_PREFIX.concat(storageKey),
      JSON.stringify(value),
    )
}
const clearIsomorphic = () => {
  if (import.meta.client && typeof localStorage?.clear === 'function')
    localStorage.clear()
}
const removeItemIsomorphic = (storageKey: string) => {
  if (import.meta.client && typeof localStorage?.removeItem === 'function')
    localStorage.removeItem(STORAGE_PREFIX.concat(storageKey))
}

export const STORAGE_PREFIX = 'lc-site__'

export const getStoredData = async <T = unknown>(
  storageKey: string,
  request: () => Promise<T>,
  maxAgeInDays = 7,
) => {
  if (import.meta.server) return {} as T

  const storedData = getItemIsomorphic<StoredData<T>>(storageKey, {})
  if (
    storedData.timestamp &&
    $dayjs().diff(storedData.timestamp, 'day') <= maxAgeInDays
  ) {
    return storedData.data
  }

  const data = await request().catch(console.error)
  if (data)
    setItemIsomorphic(storageKey, { data, timestamp: new Date().getTime() })
  return data
}

export const getStoredList = <T = unknown>(
  namespace: string,
  exceptItem = { id: '' },
  maxItems = 6,
) =>
  getItemIsomorphic<StoredData<T & { id: string }>[]>(namespace, [])
    .sort((a, b) => b.timestamp - a.timestamp)
    .filter(({ data }) => data.id !== exceptItem.id)
    .slice(0, maxItems)

export const setStoredList = (
  namespace: string,
  data: { id: string },
  maxItems = 12,
) => {
  setItemIsomorphic(
    namespace,
    [
      { data, timestamp: Date.now() },
      ...getStoredList(namespace, data, maxItems),
    ].slice(0, maxItems),
  )
}

export default {
  clear: clearIsomorphic,
  getItem: getItemIsomorphic,
  removeItem: removeItemIsomorphic,
  setItem: setItemIsomorphic,
}
