<script setup lang="ts">
import type { Locale, SupportedCurrencies } from 'lc-services/types'

const { locale } = useI18n()
const route = useRoute()
const { getNewUrl } = useSetRouteParams()
const { headerDesktop } = useHeaderDesktop()
const { headerTheme, setTemporaryTheme } = useHeaderNavigation()
const { currency, setCurrency } = useUser()
const { trackEvent } = useTracking()

withDefaults(
  defineProps<{
    areMenuItemsWhite?: boolean
  }>(),
  {
    areMenuItemsWhite: false,
  },
)

const currencies: {
  code: SupportedCurrencies
  name: string
  symbol: string
}[] = [
  {
    code: 'CHF',
    name: 'CHF',
    symbol: 'CHF',
  },
  {
    code: 'EUR',
    name: 'EUR - €',
    symbol: '€',
  },
  {
    code: 'USD',
    name: 'USD - $',
    symbol: '$',
  },
  {
    code: 'GBP',
    name: 'GBP - £',
    symbol: '£',
  },
]

const langagues: { code: Locale; name: string }[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fr',
    name: 'Français',
  },
]

const isOpen = ref(false)
const newLangUrl = ref('')
const openedTheme = ref<'white' | 'transparent' | ''>('')
const selectedLanguage = ref('')
const selectedCurrency = ref<SupportedCurrencies | ''>('')
const isActiveDropdown = computed(
  () => !!headerDesktop.value.activeDropdown.name,
)

const userCurrencySymbol = computed(
  () => currencies.find((c) => c.code === currency.value)?.symbol,
)

watch(isOpen, (value) => {
  if (value) {
    openedTheme.value = headerTheme.value
    setTemporaryTheme('white')
  }

  if (!value) {
    const theme = isActiveDropdown.value ? 'white' : openedTheme.value
    setTemporaryTheme(theme)
  }
})

watch(selectedCurrency, (value) => {
  const query = route.query

  if (query.currency && newLangUrl.value && value) {
    newLangUrl.value = newLangUrl.value.replace(
      /(currency=)[A-Z]{3}/,
      `$1${value}`,
    )
  }
})

const handleValidate = () => {
  if (selectedCurrency.value) {
    setCurrency(selectedCurrency.value)
  }

  if (selectedLanguage.value) {
    window.location.href = newLangUrl.value
  }

  reset()
}

const isCurrencySelected = (code: string) =>
  (code === currency.value && !selectedCurrency.value) ||
  code === selectedCurrency.value

const isLangSelected = (code: string) =>
  (code === locale.value && !selectedLanguage.value) ||
  code === selectedLanguage.value

const reset = () => {
  isOpen.value = false
  selectedLanguage.value = ''
  selectedCurrency.value = ''
}

const switchLang = (code: Locale) => {
  selectedLanguage.value = code
  newLangUrl.value = getNewUrl(code)
}

const switchCurrency = (code: SupportedCurrencies) => {
  selectedCurrency.value = code
}

const toggle = () => {
  isOpen.value = !isOpen.value

  if (isOpen.value) {
    trackEvent({
      event: 'header_clicked',
      item: 'language-currency',
    })
  }
}

onMounted(() => {
  document.addEventListener('scroll', reset)
})

onUnmounted(() => {
  document.removeEventListener('scroll', reset)
})
</script>

<template>
  <div v-click-outside="reset" class="relative mx-4 flex h-full">
    <button
      :class="['flex items-center', { 'text-white': areMenuItemsWhite }]"
      type="button"
      @click="toggle"
    >
      <div class="mr-3.5 flex items-center text-md">
        {{ $i18n.locale.toUpperCase() }}
        -
        {{ userCurrencySymbol }}
      </div>

      <BaseIcon :class="{ 'rotate-180': isOpen }" name="navArrowDown" />
    </button>

    <transition name="fade">
      <div
        v-if="isOpen"
        class="absolute right-0 top-[calc(100%+1.5rem)] mt-2 w-[380px] rounded border border-gray-200 bg-white p-6 shadow-lg"
      >
        <div class="mb-9">
          <div class="mb-3.5 text-md font-medium">
            {{ $t('header.language') }}
          </div>
          <div class="flex items-center">
            <div
              v-for="(language, i) in langagues"
              :key="language.code"
              class="flex items-center"
            >
              <button
                type="button"
                :class="[
                  'cursor-pointer text-md',
                  {
                    'text-gray-500': !isLangSelected(language.code),
                  },
                ]"
                @click="switchLang(language.code)"
              >
                {{ language.name }}
              </button>
              <span
                v-if="i < langagues.length - 1"
                class="mx-5 h-3.5 w-px bg-gray-200"
              />
            </div>
          </div>
        </div>
        <div class="mb-9">
          <div class="mb-3.5 text-md font-medium">
            {{ $t('header.currency') }}
          </div>
          <div class="flex items-center">
            <div
              v-for="(currencyItem, i) in currencies"
              :key="currencyItem.code"
              class="flex items-center"
            >
              <button
                type="button"
                :class="[
                  'text-md',
                  {
                    'text-gray-500': !isCurrencySelected(currencyItem.code),
                  },
                ]"
                @click="switchCurrency(currencyItem.code)"
              >
                {{ currencyItem.name }}
              </button>
              <span
                v-if="i < currencies.length - 1"
                class="mx-5 h-3.5 w-px bg-gray-200"
              />
            </div>
          </div>
        </div>
        <base-button color="secondary" block-full @click="handleValidate">
          {{ $t('global.validate') }}
        </base-button>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}

.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
