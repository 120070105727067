<script setup lang="ts">
const { housesWishlistActiveInHeader } = useMultipleWishlist()
const { setSummaryOpen, summaryOpen } = useMultipleWishlistSummary()
const { headerTheme } = useHeaderNavigation()
const { isNewHeader } = useHeaderV2()
const { trackEvent, pageType } = useTracking()

const colorIcon = computed(() =>
  headerTheme.value === 'transparent' ? 'white' : 'black',
)

const svgStrokeClass = computed(() => {
  if (housesWishlistActiveInHeader.value.exist) {
    return 'duration-1000 fill-primary-800 stroke-primary-800'
  }

  return `duration-200 fill-transparent ${headerTheme.value === 'transparent' ? 'stroke-white' : 'stroke-gray-700'}`
})

const handleClick = () => {
  setSummaryOpen(!summaryOpen.value)

  if (isNewHeader.value) {
    trackEvent({
      event: 'wishlist_icon_click',
      page_type: pageType.value,
    })
  } else {
    trackEvent({
      event: 'header_clicked',
      item: 'wishlist',
    })
  }
}
</script>

<template>
  <BaseButton
    id="header-wishlist"
    :aria-label="$t('header.wishlist')"
    :color="colorIcon"
    class="group relative !border-0"
    has-icon
    no-padding
    role="menu"
    type="button"
    @click="handleClick"
  >
    <BaseIcon
      name="heart"
      :class="[
        'stroke-1 transition  ease-in-out ',
        svgStrokeClass,
        {
          'group-hover:stroke-[1.5]':
            isNewHeader && !housesWishlistActiveInHeader.exist,
        },
      ]"
    />
    <span
      v-if="housesWishlistActiveInHeader.exist"
      class="absolute inset-0 flex items-center justify-center text-[7.5px] text-white"
    >
      {{ housesWishlistActiveInHeader.number }}
    </span>
    <span
      v-if="isNewHeader && !housesWishlistActiveInHeader.exist"
      :class="[
        'absolute -bottom-2 hidden h-1 w-6 border-b group-hover:block',
        headerTheme === 'transparent' ? 'border-white' : 'border-gray-700',
      ]"
    />
  </BaseButton>
</template>
