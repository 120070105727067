<script setup lang="ts">
const { headerTheme, setTemporaryTheme } = useHeaderNavigation()
const {
  closeDropdownAndOpenActiveTab,
  headerDesktop,
  resetTab,
  setActiveDropdown,
} = useHeaderDesktop()
const { summaryOpen } = useMultipleWishlistSummary()
const { trackEvent } = useTracking()

const currentDropdown = computed(() => headerDesktop.value.activeDropdown)
const tabUnderlined = computed(() => headerDesktop.value.tabUnderlined)
const colorIcon = computed(() =>
  headerTheme.value === 'transparent' ? 'white' : 'black',
)

watch(summaryOpen, async (value) => {
  if (value) {
    await closeDropdownAndOpenActiveTab(
      'wishlist',
      document.getElementById('header-wishlist'),
    )
    setTemporaryTheme('white')
  } else {
    resetTab('right')
    setTemporaryTheme('')
  }
})

// Methods
const toggleDropdownSearch = (name: string, index: number) => {
  setActiveDropdown(name, index)

  trackEvent({
    event: 'header_clicked',
    item: 'search',
  })
}
</script>

<template>
  <nav class="navigation-icons" :aria-label="$t('header.secondaryNavigation')">
    <ul class="m-0 flex items-center">
      <li class="leading-none">
        <BaseButton
          id="header-search"
          :aria-expanded="currentDropdown.name ? true : false"
          :aria-label="$t('header.search')"
          :color="colorIcon"
          aria-controls="header-dropdown"
          class="!border-0"
          no-padding
          has-icon
          role="menu"
          type="button"
          @click="toggleDropdownSearch('search', 3)"
        >
          <BaseIcon
            name="search"
            :stroke-width="tabUnderlined === 'search' ? 1.3 : 1"
          />
        </BaseButton>
      </li>
      <li class="ml-4">
        <TheHeaderButtonWishlist />
      </li>
      <li class="ml-5">
        <TheHeaderAccountButton />
      </li>
    </ul>
  </nav>
</template>

<style>
.icon-transition {
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
}

.navigation-icons .link {
  @apply hover:no-underline;
}

.navigation-icons .link .white {
  @apply text-white;
}

.navigation-icons .link .black {
  @apply text-gray-700;
}

.phone-wrap .lc-link--black:hover {
  @apply text-gray-700;
}
</style>
