import { GetDestinationPhotoRepository } from "./interfaces/GetDestinationPhotoRepository";
import { GetDestinationPhotoPresentation } from "./interfaces/GetDestinationPhotoPresentation";

export default class GetDestinationPhotoUseCase {
  constructor(private repository: GetDestinationPhotoRepository) {}

  async execute(
    presenter: GetDestinationPhotoPresentation,
    destinationId: string
  ) {
    const destiInfo = await this.repository.getDestinationById({
      destinationId,
      include: {
        include: [
          "cluster.cover_featured_photo",
          "cluster",
          "country.cover_featured_photo",
          "country",
          "cover_featured_photo",
          "seo_featured_photo",
        ],
      },
    });
    const photoUrl = Array.isArray(destiInfo.included?.photos)
      ? destiInfo.included?.photos?.find(
          (photo) => photo.id === destiInfo?.data?.coverFeaturedPhotoId
        )?.url
      : destiInfo.included?.photos?.url;

    presenter.displayDestinationPhoto({
      id: destinationId,
      photo: photoUrl,
    });
  }
}
