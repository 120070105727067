import { HouseTruthLink } from "../../../types";

export class HouseTruthLinksDomain {
  private houseTruthLinks: Pick<HouseTruthLink, "description">[] = [];

  constructor(private houseTruthLinksRepository: HouseTruthLink[]) {
    this.houseTruthLinks = this.houseTruthLinksRepository.map((truthLinks) => ({
      description: truthLinks.description,
    }));
  }

  build() {
    return this.houseTruthLinks;
  }
}
