<script setup lang="ts">
defineProps<{
  step: number
}>()
defineEmits<{ 'change-step': [number] }>()
defineSlots<{ default(): any }>()

const { t } = useI18n()
const { isMobile } = useBreakpoint()
</script>

<template>
  <div v-if="step < 3" :class="{ 'mx-4 lg:mx-8': step === 1 }">
    <h2 class="mb-6 text-3xl">{{ t('title') }}</h2>
    <p v-if="step === 1" class="m-0">
      {{ t('subtitle') }}
    </p>
    <div
      v-else-if="step === 2"
      class="flex items-center justify-between rounded bg-primary-100 p-4"
    >
      <div class="flex items-center gap-2">
        <BaseIcon v-if="!isMobile" name="simpleClock" :size="1.25" />
        <slot />
      </div>
      <BaseButton
        class="text-md"
        variant="link"
        @click="$emit('change-step', 1)"
      >
        {{ $t('global.modify') }}
      </BaseButton>
    </div>
  </div>
</template>

<i18n lang="yaml">
fr:
  title: Planifier un appel avec un conseiller
  subtitle: Choisissez un créneau dans l’agenda et vous serez recontacté pour discuter de votre projet.
en:
  title: Schedule a call with an advisor
  subtitle: Choose a time slot in the agenda below and we'll get back to you to talk about your project.
</i18n>
