import type {
  CreateBooking,
  CustomFormField,
  HubspotMeetingLink,
  HubspotLinkAvailability,
} from '~/types/hubspot'

export type MeetingSchedulerProps<
  CustomFormValues extends Record<string, string | number | boolean> = Record<
    string,
    string | number | boolean
  >,
> = {
  currentDate: Date
  customFormValues?: CustomFormValues
  meetingName?: string
  meetingSlug: string
  meetingType?: 'PERSONAL_LINK' | 'GROUP_CALENDAR' | 'ROUND_ROBIN_CALENDAR'
  monthOffset: Ref<number>
  organizerId?: string
  userInfos?: {
    email: string
    firstName?: string
    lastName?: string
  }
}

export const useHubspotMeetingScheduler = <
  CustomFormValues extends Record<string, string | number | boolean> = Record<
    string,
    string | number | boolean
  >,
>({
  currentDate,
  customFormValues,
  meetingSlug,
  monthOffset,
  userInfos,
}: MeetingSchedulerProps<CustomFormValues>) => {
  const now = currentDate.getTime()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  const selectedDate = ref<string | undefined>()

  const { data: meeting, execute: fetchMeeting } = useFetch<HubspotMeetingLink>(
    `/api/hubspot/meetings/${meetingSlug}`,
    {
      immediate: false,
      onRequest: (req) => {
        req.options.query = { timezone: formValues.timezone }
      },
    },
  )

  const { data: linkAvailability, execute: fetchAvailabilities } =
    useFetch<HubspotLinkAvailability>(
      `/api/hubspot/meetings/availabilities/${meetingSlug}`,
      {
        immediate: false,
        params: { monthOffset },
        onRequest: (req) => {
          req.options.query = {
            ...req.options.query,
            timezone: formValues.timezone,
          }
        },
      },
    )

  const customFormFields = computed(
    () => meeting.value?.customParams.formFields ?? [],
  )
  const formValues = reactive<CreateBooking<CustomFormValues>>({
    duration: 1800000,
    email: userInfos?.email ?? '',
    firstName: userInfos?.firstName ?? '',
    formFields:
      customFormValues ??
      (Object.fromEntries(
        customFormFields.value.map((field) => [
          field.name,
          field.type === 'number'
            ? 0
            : field.type === 'enumeration'
              ? false
              : '',
        ]),
      ) as CustomFormValues),
    lastName: userInfos?.lastName ?? '',
    slug: meetingSlug,
    startTime: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })

  const availableDurations = computed(() =>
    Object.keys(linkAvailability.value?.linkAvailabilityByDuration ?? {}).map(
      Number,
    ),
  )
  const availabilities = computed(() =>
    (
      linkAvailability.value?.linkAvailabilityByDuration[
        formValues.duration.toString()
      ]?.availabilities ?? []
    )
      .filter((c) => c.startMillisUtc > now)
      .reduce(
        (acc, c) => {
          const date = new Date(c.startMillisUtc)
          const day = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
          ).getTime()
          return {
            ...acc,
            [day]: [...(acc[day] ?? []), c.startMillisUtc],
          }
        },
        {} as Record<number, number[]>,
      ),
  )
  const isValid = computed(
    () =>
      selectedDate.value &&
      Object.values(formValues).every(Boolean) &&
      availabilities.value[Number(selectedDate.value)]?.includes(
        formValues.startTime,
      ) &&
      Object.entries(formValues.formFields ?? {})
        .filter(
          ([key]) =>
            customFormFields.value.find((field) => field.name === key)
              ?.isRequired,
        )
        .every(([, value]) => Boolean(value)),
  )
  const maxDate = computed(() => {
    // Do not set a max date if there are more availabilities next month
    if (linkAvailability.value?.hasMore) return undefined
    // Max date can be derived from availabilities
    const latestKey = Math.max(...Object.keys(availabilities.value).map(Number))
    if (Array.isArray(availabilities.value[latestKey])) {
      const timestamp = Math.max(...availabilities.value[latestKey])
      return new Date(timestamp)
    }
    // Otherwise return the last day of the selected month
    const firstDayOfNextMonth = new Date(
      currentYear,
      currentMonth + monthOffset.value + 1,
    ).getTime()
    return new Date(firstDayOfNextMonth - 24 * 60 * 60 * 1000)
  })
  const disabledDates = computed(() => {
    const available = [...Object.keys(availabilities.value)].map(Number)
    if (!available.length) selectedDate.value = undefined
    const date = new Date(currentYear, currentMonth + monthOffset.value)
    const month = date.getMonth()
    const unavailable = []
    while (date.getMonth() === month) {
      if (!available.includes(date.getTime())) unavailable.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }
    return unavailable.map((date) => date.getTime())
  })

  const getCustomFieldAttrs = (field: CustomFormField) => {
    switch (field.fieldType) {
      case 'phonenumber':
        return { is: 'input', type: 'tel' }
      case 'booleancheckbox':
      case 'checkbox':
        return { is: 'input', type: 'checkbox' }
      case 'select':
      case 'textarea':
        return { is: field.fieldType }
      default:
        return { is: 'input', type: field.fieldType }
    }
  }

  const onDurationSelect = (duration: number) => {
    formValues.duration = duration
  }
  const onTimezoneSelect = async (timezone?: string) => {
    if (typeof timezone === 'string') {
      formValues.timezone = timezone
      await fetchAvailabilities({ dedupe: true })
    }
  }
  const onDateSelect = (date?: string) => {
    selectedDate.value = date
  }
  const onTimeSelect = (time: number) => {
    formValues.startTime = time
  }
  const onChangeFormField = (
    field: 'email' | 'firstName' | 'lastName' | string,
    value?: string | number | boolean,
  ) => {
    switch (field) {
      case 'email':
        formValues.email = value as string
        break
      case 'firstName':
        formValues.firstName = value as string
        break
      case 'lastName':
        formValues.lastName = value as string
        break
      default:
        ;(formValues.formFields as Record<string, unknown>)[field] = value
    }
  }

  const mutation = async (body: CreateBooking) =>
    $fetch('/api/hubspot/meetings/book', {
      body,
      method: 'POST',
    })

  return {
    availabilities,
    availableDurations,
    customFormFields,
    formValues,
    fetchAvailabilities,
    getCustomFieldAttrs,
    fetchMeeting,
    disabledDates,
    isValid,
    maxDate,
    meeting,
    mutation,
    onChangeFormField,
    onDateSelect,
    onDurationSelect,
    onTimeSelect,
    onTimezoneSelect,
    selectedDate,
  }
}
