export default defineNuxtPlugin((nuxtApp) => {
  const { $httpCookies } = nuxtApp
  const headers = useRequestHeaders()
  const browserLanguageCookie = $httpCookies?.get('browser_language')
  const browserLanguage = useState('browserLanguage')

  const extractCountryCode = (languageString) => {
    const firstLanguage = languageString.split(',')[0]
    const matchWithCountry = /^[a-z]{2}-([A-Z]{2})$/.exec(firstLanguage)

    if (matchWithCountry) return matchWithCountry[1]

    const matchSimpleCode = /^(en|fr)$/.exec(firstLanguage)

    if (matchSimpleCode) return matchSimpleCode[1].toUpperCase()

    return null
  }

  if (import.meta.server) {
    if (browserLanguageCookie) {
      browserLanguage.value = browserLanguageCookie
    } else if (headers['accept-language']) {
      const countryCode = extractCountryCode(headers['accept-language'])
      browserLanguage.value = countryCode || browserLanguage.value
    }
  } else if (!browserLanguageCookie) {
    $httpCookies?.set('browser_language', browserLanguage.value)
  }
})
