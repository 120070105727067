import { GetSimilarHousesPricesByPeriodRepository } from "./interfaces/GetSimilarHousesPricesByPeriodRepository";
import { GetSimilarHousesPricesByPeriodPresentation } from "./interfaces/GetSimilarHousesPricesByPeriodPresentation";
import { GetSimilarHousesPricesByPeriodInput, SimilarHousePrices } from "types";

export default class GetSimilarHousesPricesByPeriodUseCase {
  constructor(private repository: GetSimilarHousesPricesByPeriodRepository) {}

  async execute(
    presenter: GetSimilarHousesPricesByPeriodPresentation,
    input: GetSimilarHousesPricesByPeriodInput
  ) {
    const similarHousePrices = await Promise.all(
      input.houseIds.map(async (houseId) => {
        const response = await this.repository.getHousePeriodsById({
          houseId: houseId,
          startDate: input.startDate,
          endDate: input.endDate,
          currency: input.currency,
        });
        if (response.data) {
          const houseWithPriceResult: SimilarHousePrices = {
            houseId: response.data.id,
            publicPrice: response.data.publicPrice,
            shouldDisplay: response.data.fullyCovered,
          };
          return houseWithPriceResult;
        }
      })
    );

    const filteredHousePrices: SimilarHousePrices[] = similarHousePrices.filter(
      (item): item is SimilarHousePrices => item !== undefined
    );
    presenter.displayHousesPrices(filteredHousePrices);
  }
}
