import { GetDestinationFavoritePeriodRepository } from "./interfaces/GetDestinationFavoritePeriodRepository";
import { GetDestinationFavoritePeriodPresentation } from "./interfaces/GetDestinationFavoritePeriodPresentation";

export default class GetDestinationFavoritePeriodUseCase {
  constructor(private repository: GetDestinationFavoritePeriodRepository) {}

  async execute(
    presenter: GetDestinationFavoritePeriodPresentation,
    input: {
      destinationId: string[];
      startDate: string;
    },
    minPercent = 0
  ) {
    try {
      const response = await this.repository.getCountByPeriodTypes(input);

      if (response?.data) {
        const totalCount = Object.values(response.data).reduce(
          (acc, c) => acc + c,
          0
        );
        const favoritePeriod = Object.entries(response.data).reduce(
          (acc, [period, value]) => {
            const current = Math.round((value / totalCount) * 100);
            const { percent } = acc;
            return current > percent ? { percent: current, period } : acc;
          },
          { percent: 0, period: "" }
        );

        if (favoritePeriod.percent > minPercent) {
          presenter.displayDestinationFavoritePeriod(favoritePeriod);
        }
      }
    } catch (err) {
      console.log("GetDestinationFavoritePeriodUseCase", err);
    }
  }
}
