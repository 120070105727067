import Cookies from 'universal-cookie/es6/Cookies'

export default defineNuxtPlugin((_) => {
  const headersCookie = useRequestHeaders(['cookie'])
  const MAX_AGE = 3600 * 24 * 30

  const cookies = new Cookies(headersCookie?.cookie || null, {
    path: '/',
    maxAge: MAX_AGE,
  })

  return {
    provide: {
      httpCookies: cookies,
    },
  }
})
