<script setup lang="ts">
import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js'

import type { Color } from './BaseForm/types'

const { user } = useUser()
const { phoneNumber } = useContactPhoneNumber()

const { color = 'primary', phone = null } = defineProps<{
  color?: Color
  phone?: string | null
}>()
defineEmits<{
  click: [MouseEvent]
}>()

const phoneNumberOther = computed(() => {
  try {
    return typeof phone === 'string' && phone
      ? parsePhoneNumber(
          phone,
          (user.value.country as CountryCode) ?? undefined,
        ).formatInternational()
      : null
  } catch {
    // if error return raw value
    return phone
  }
})
const phoneNumberLC = computed(() =>
  typeof phone !== 'string' ? phoneNumber.value : null,
)
</script>

<template>
  <BaseButton
    v-if="phoneNumberLC"
    :color="color"
    :href="`tel:${phoneNumberLC}`"
    variant="link"
    @click="$emit('click', $event)"
  >
    {{ phoneNumberLC }}
  </BaseButton>
  <span v-else-if="phoneNumberOther">{{ phoneNumberOther }}</span>
</template>
