import aa from 'search-insights'

const CACHE_KEY = 'site__algolia-tracking'

const createCacheObject = (
  houseId: string,
  index: string,
  queryId: string | null,
) => {
  const key = `${index}_${houseId}`

  return { [key]: [queryId, new Date().getTime()] }
}

const cleanOldEntries = (data: Record<string, [string | null, number]>) => {
  const oneDayInMs = 3600000 * 24
  const currentTime = Date.now()

  return Object.fromEntries(
    Object.entries(data).filter(
      ([_, [, timestamp]]) => currentTime - timestamp <= oneDayInMs,
    ),
  )
}

const getInfoLocalStorageAlgolia = (houseId: string) => {
  const algoliaCache = localStorage.getItem(CACHE_KEY)
  if (!algoliaCache) return null

  if (algoliaCache) {
    const algolia = JSON.parse(algoliaCache) as Record<
      string,
      [string | null, number]
    >

    const matches = Object.entries(algolia)
      .filter(([k]) => k.endsWith(`_${houseId}`))
      .map(([key, [queryId, timestamp]]) => ({
        key: key.slice(0, key.lastIndexOf('_')),
        queryId,
        timestamp,
      }))

    if (matches.length === 0) return null

    const latest = matches.reduce((acc, curr) =>
      curr.timestamp > acc.timestamp ? curr : acc,
    )

    return { index: latest.key, queryId: latest.queryId }
  }
}

export const useAlgoliaSearchInsights = () => {
  const { authUserIdTracking } = useAuth()
  const config = useRuntimeConfig()

  const initTrackAlgolia = () => {
    aa('init', {
      appId: config.public.algoliaId,
      apiKey: config.public.algoliaSearchKey,
      useCookie: true,
    })
  }

  const trackAlgoliaClick = ({
    indexName,
    objectIDs,
    positions,
    queryID,
  }: {
    indexName: string
    objectIDs: string[]
    positions?: number[]
    queryID?: string
  }) => {
    const eventParams = {
      authenticatedUserToken: authUserIdTracking.value,
      index: indexName,
      objectIDs,
    }

    if (queryID && positions?.length) {
      aa('clickedObjectIDsAfterSearch', {
        ...eventParams,
        eventName: 'House clicked after search (without date)',
        positions,
        queryID,
      })
    } else {
      aa('clickedObjectIDs', {
        ...eventParams,
        eventName: 'House clicked after search (with date)',
      })
    }

    const existingCache = localStorage.getItem(CACHE_KEY)
    const newObject = createCacheObject(
      objectIDs[0],
      indexName,
      queryID || null,
    )

    const updatedCache = existingCache
      ? { ...cleanOldEntries(JSON.parse(existingCache)), ...newObject }
      : newObject

    localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCache))
  }

  const trackAlgoliaConversion = (
    houseId: string,
    name: 'Pre-booking' | 'Call' | 'Inquiry',
  ) => {
    const algoliaInfo = getInfoLocalStorageAlgolia(houseId)

    if (algoliaInfo) {
      const eventParams = {
        authenticatedUserToken: authUserIdTracking.value,
        index: algoliaInfo.index,
        objectIDs: [houseId],
      }

      if (algoliaInfo.index && algoliaInfo.queryId) {
        aa('convertedObjectIDsAfterSearch', {
          ...eventParams,
          eventName: `${name} converted after search (without date)`,
          queryID: algoliaInfo.queryId,
        })
      } else if (algoliaInfo.index) {
        aa('convertedObjectIDs', {
          ...eventParams,
          eventName: `${name} converted after search (with date)`,
        })
      }
    }
  }

  return {
    initTrackAlgolia,
    trackAlgoliaClick,
    trackAlgoliaConversion,
  }
}
