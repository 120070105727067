import { hash } from '~/utils/string'

// Hash Phone + E-mail for GTM
export const hashDataAndSetForGtm = async (data: {
  email?: string
  phone?: string
}) => {
  type WindowWithFormHashedEmail = Window &
    typeof globalThis & {
      formHashedEmail: string
      formHashedPhone: string
    }

  const W = window as WindowWithFormHashedEmail

  let hashedEmail = ''
  let hashedPhone = ''

  if (data?.email)
    hashedEmail = await hash(data?.email?.replace(/\s/g, '') || '')
  if (data?.phone)
    hashedPhone = await hash(data?.phone?.replace(/\s/g, '') || '')

  if (hashedEmail) W.formHashedEmail = hashedEmail
  if (hashedPhone) W.formHashedPhone = hashedPhone
}
