/**
 *
 * @param {{ $strapiApi: import('#app').NuxtApp['$strapiApi']}} nuxtApp
 * @returns {{ strapiInspirationsIds: string[], strapiInspirations: {
 *            id: string,
 *            destinationId: number,
 *            position: number,
 *            titleFr: string,
 *            titleEn: string,
 *            coverPhotoUrl: string,
 *            slugFr: string,
 *            slugEn: string,
 * }[] }}
 */

export const getInspirations = ({ $strapiApi }) => {
  return $strapiApi
    .getAllInspirations()
    .then((res) => {
      if (res?.data?.length) {
        const inspirations = res.data
          .map((inspiration) => {
            const {
              destination_id,
              id,
              position,
              title_en,
              title_fr,
              lp_inspiration: {
                data: { attributes },
              },
            } = inspiration

            return {
              id,
              destinationId: Number(destination_id),
              position,
              titleFr: title_fr,
              titleEn: title_en,
              coverPhotoUrl: attributes.cover_photo_url,
              slugFr: attributes.slug_fr,
              slugEn: attributes.slug_en,
            }
          })
          .sort((a, b) => a.position - b.position)
        const inspirationsIds = inspirations
          .map((inspiration) => inspiration.destinationId)
          .flat()

        return {
          strapiInspirations: inspirations,
          strapiInspirationsIds: inspirationsIds,
        }
      }

      return {
        strapiInspirations: [],
        strapiInspirationsIds: [],
      }
    })
    .catch((error) => {
      console.error(error?.response?.data?.error)

      return {
        strapiInspirations: [],
        strapiInspirationsIds: [],
      }
    })
}
