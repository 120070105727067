<script setup lang="ts">
defineSlots<{ default(): any }>()

const localePath = useLocalePath()
const { t } = useI18n({ useScope: 'local' })
const route = useRoute()
const config = useRuntimeConfig()
const { trackEvent } = useTracking()
const { startTimeWeek, endTimeWeek, saturdayEndTimeWeek } =
  useCallCenterSchedule()
const { headerTheme } = useHeaderNavigation()
const { setSummaryOpen, summaryOpen } = useMultipleWishlistSummary()
const { isNewHeader } = useHeaderV2()

const dayUTC = new Date().getUTCDay()
const parisTime = new Date().toLocaleTimeString('fr-FR', {
  timeZone: 'Europe/Paris',
})
const [hour, minute] = parisTime.split(':')

const nameRoute = computed(() => String(route.name ?? '').slice(0, -5))
const transparentColor = computed(() => headerTheme.value === 'transparent')
const callsUntilMidnight = computed(
  () => config.public.callsUntilMidnight === 'true',
)

const paris = new Date()
paris.setUTCHours(Number(hour))
paris.setUTCMinutes(Number(minute))

const openWeekDay = new Date()
openWeekDay.setUTCHours(9)
openWeekDay.setUTCMinutes(30)

const closeWeekDay = new Date()
closeWeekDay.setUTCHours(callsUntilMidnight.value ? 23 : 18)
closeWeekDay.setUTCMinutes(callsUntilMidnight.value ? 59 : 30)

const openWeekendDay = new Date()
openWeekendDay.setUTCHours(9)
openWeekendDay.setUTCMinutes(30)

const closeWeekendDay = new Date()
closeWeekendDay.setUTCHours(18)
closeWeekendDay.setUTCMinutes(30)

const currentlyOpenWeekDay = computed(() => {
  return (
    ![0, 6].includes(dayUTC) &&
    paris.getTime() >= openWeekDay.getTime() &&
    paris.getTime() <= closeWeekDay.getTime()
  )
})

const currentlyOpenWeekendDay = computed(() => {
  return (
    [6].includes(dayUTC) &&
    paris.getTime() >= openWeekendDay.getTime() &&
    paris.getTime() <= closeWeekendDay.getTime()
  )
})

const isOpen = computed(
  () => currentlyOpenWeekDay.value || currentlyOpenWeekendDay.value,
)

const handleTracking = () => {
  trackEvent({
    event: 'header_clicked',
    item: 'contact',
  })
}

const handleClickPhone = () => {
  trackEvent({
    event: 'phone_number_click',
    label: { section: 'header' },
    source: 'header',
  })
}

const handleMouseoverPhone = () => {
  if (summaryOpen) setSummaryOpen(false)
}

const showPhoneText = computed(() =>
  ['index', 'house-slug'].includes(nameRoute.value),
)
</script>

<template>
  <div class="group relative">
    <BasePhoneNumber
      v-if="showPhoneText"
      :color="transparentColor ? 'white' : 'black'"
      class="!text-md !font-medium"
      @click="handleClickPhone"
      @mouseover="handleMouseoverPhone"
    />
    <button
      v-else
      class="relative block"
      type="button"
      @click="
        trackEvent({
          event: 'header_clicked',
          item: 'phone',
        })
      "
      @mouseover="handleMouseoverPhone"
    >
      <BaseIcon
        name="phone"
        :class="[
          'icon-transition stroke-1',
          transparentColor ? 'text-white' : 'text^-gray-700',
          { 'group-hover:stroke-[1.5]': isNewHeader },
        ]"
      />

      <span
        v-if="isNewHeader"
        :class="[
          'absolute -bottom-2 hidden h-1 w-6 border-b group-hover:block',
          transparentColor ? 'border-white' : 'border-gray-700',
        ]"
      />
    </button>

    <div class="absolute right-0 z-fixed hidden group-hover:block">
      <div
        :class="[
          'mt-5 flex w-max flex-col gap-4 rounded p-6 shadow-normal ',
          { 'bg-gray-700/85': transparentColor },
          { 'bg-white': !transparentColor },
        ]"
      >
        <div
          :class="[
            'flex items-center gap-4 text-left',
            transparentColor ? 'text-white' : 'text-gray-700',
          ]"
        >
          <BaseIcon class="flex-none" name="phone" />

          <div
            class="the-header-phone-schedule m-0 text-md leading-5"
            data-testid="the-header-phone-schedule"
          >
            <div v-if="!showPhoneText" class="flex items-center gap-1">
              <BasePhoneNumber
                :color="transparentColor ? 'white' : 'black'"
                class="!text-md !font-bold !leading-5 !underline"
                @click="handleClickPhone"
              />
              -
              <span class="block font-semibold">
                <template v-if="isOpen">
                  <span :class="{ 'text-secondary-400': !transparentColor }">
                    {{ t('currentlyOpen') }}
                  </span>
                </template>
                <template v-else>
                  {{ t('currentlyClosed') }}
                </template>
              </span>
            </div>
            <div>
              {{
                t('contactUs', {
                  startTimeWeek,
                  endTimeWeek,
                  saturdayEndTimeWeek,
                })
              }}
            </div>
            <span v-if="showPhoneText" class="block font-semibold">
              <template v-if="isOpen">
                <span :class="{ 'text-secondary-400': !transparentColor }">
                  {{ t('currentlyOpen') }}
                </span>
              </template>
              <template v-else>
                {{ t('currentlyClosed') }}
              </template>
            </span>
          </div>
        </div>

        <div
          :class="[
            'flex items-center gap-4 text-left',
            transparentColor ? 'text-white' : 'text-gray-700',
          ]"
        >
          <BaseIcon class="flex-none" name="mail" />
          <i18n-t
            keypath="contactHelp"
            tag="span"
            class="text-md"
            data-testid="the-header-phone-contact"
          >
            <template #link>
              <NuxtLink
                :to="localePath('contact')"
                :class="[
                  'font-bold underline',
                  transparentColor ? 'text-white' : 'text-gray-700',
                ]"
                @click="handleTracking"
              >
                {{ t('form') }}
              </NuxtLink>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "contactHelp": "Or contact us at anytime {link}",
    "contactUs": "Mon. to Fri. from {startTimeWeek} to {endTimeWeek} - Sat. from {startTimeWeek} to {saturdayEndTimeWeek}",
    "currentlyClosed": "Currently closed",
    "currentlyOpen": "Currently open",
    "form": "here"
  },
  "fr": {
    "contactHelp": "Ou contactez-nous à tout moment via ce {link}",
    "contactUs": "Lun. au ven. de {startTimeWeek} à {endTimeWeek} - le sam de {startTimeWeek} à {saturdayEndTimeWeek}",
    "currentlyClosed": "Fermé actuellement",
    "currentlyOpen": "Ouvert actuellement",
    "form": "formulaire"
  }
}
</i18n>
