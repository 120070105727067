<script setup lang="ts">
defineProps<{ error?: boolean }>()
defineEmits<{ restart: [] }>()
defineSlots<{ default(): any }>()

const { t } = useI18n()
</script>

<template>
  <div class="flex size-full flex-col items-center justify-center gap-8">
    <template v-if="error">
      <div
        class="flex size-12 items-center justify-center rounded-full bg-errorBg"
      >
        <BaseIcon
          class="rounded-full border border-error p-0.5"
          name="cancel"
          color="text-error"
          :size="1.5"
        />
      </div>
      <div>
        <h4>{{ t('error.title') }}</h4>
        <p>{{ t('error.message') }}</p>
      </div>
      <BaseButton
        type="button"
        variant="btn"
        color="secondary"
        @click="$emit('restart')"
      >
        {{ t('error.restart') }}
      </BaseButton>
    </template>

    <template v-else>
      <img src="~/assets/images/hote.svg" class="size-24" />
      <h4>{{ t('title') }}</h4>
      <div class="flex flex-col items-center">
        <div class="flex w-fit items-center gap-2 rounded bg-primary-100 p-2">
          <BaseIcon name="simpleClock" :size="1.25" />
          <slot />
        </div>
        <p class="mt-2">{{ t('message') }}</p>
      </div>
    </template>
  </div>
</template>

<i18n lang="yaml">
fr:
  title: Rendez-vous confirmé
  message: Une invitation vous a été envoyée par e-mail.
  error:
    title: Une erreur est survenue
    message: Veuillez recommencer votre demande
    restart: Recommencer
en:
  title: Meeting confirmed
  message: We sent you an invitation by e-mail.
  error:
    title: An error occurred
    message: Please try scheduling your appointment again
    restart: Try again
</i18n>
