export type CredentialResponseType = {
  sub: string
  email: string
  given_name: string
  family_name?: string
  name: string
}

export default defineNuxtRouteMiddleware(() => {
  const { $lcServicesAuth, $api } = useNuxtApp()
  const { authController, authPresenter } = $lcServicesAuth

  const { login, setSignUp, setCredentials, setAuthenticated } = useAuth()
  const router = useRouter()
  const appleAuthData = useCookie<CredentialResponseType | null>(
    'apple-auth-data',
  )
  const googleAuthData = useCookie<CredentialResponseType | null>(
    'google-auth-data',
  )

  const loginWithProviderHandle = async (
    provider: 'google' | 'apple',
    data: CredentialResponseType,
  ) => {
    await authController.loginWithProviders({
      webProps: {
        provider,
        uuid: data.sub,
        email: data.email,
      },
    })

    setCredentials(data)

    if (authPresenter.vm.navigation === 'App') {
      const { data } = await $api.v1.auth.validateToken()
      await login(data)
      setAuthenticated(true)
    }

    if (authPresenter.vm.navigation === 'SignUp') setSignUp(true)

    router.afterEach(() => {
      googleAuthData.value = null
      appleAuthData.value = null
    })
  }

  if (googleAuthData.value)
    loginWithProviderHandle('google', googleAuthData.value)

  if (appleAuthData.value) loginWithProviderHandle('apple', appleAuthData.value)
})
