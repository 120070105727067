import type { HouseDetails } from '~/types/house/types'
import type { StateModal } from '~/types/types'

declare global {
  interface Window {
    openMeetingScheduler?: (source: string) => void
    closeMeetingScheduler?: () => void
  }
}

export const useMeetingScheduler = () => {
  const stateModal = useState<StateModal>('state-modal')
  const stateScheduler = useState<{
    source: string
    house?: HouseDetails
  }>('meeting-scheduler', () => ({
    source: 'footer',
  }))
  const { pageType, trackEvent } = useTracking()

  const schedulerIsOpen = computed(() => stateModal.value.schedulerIsOpen)
  const requestedHouse = computed(() => stateScheduler.value.house)

  const openScheduler = (source?: string, house?: HouseDetails) => {
    trackEvent({
      category: 'General',
      event: 'meeting_inquiry_started',
      page_type: pageType.value,
      source,
    })
    stateScheduler.value = {
      house,
      source: source ?? stateScheduler.value.source,
    }
    stateModal.value.schedulerIsOpen = true
  }

  const closeScheduler = () => {
    stateModal.value.schedulerIsOpen = false
  }
  if (import.meta.client) {
    if (typeof window.openMeetingScheduler === 'undefined')
      window.openMeetingScheduler = openScheduler
    if (typeof window.closeMeetingScheduler === 'undefined')
      window.closeMeetingScheduler = closeScheduler
  }

  const trackSchedulerSuccess = () => {
    const payload = requestedHouse.value
      ? {
          cluster_name: requestedHouse.value.destination?.cluster?.name ?? '',
          country_name: requestedHouse.value.destination?.country?.name ?? '',
          destination_name: requestedHouse.value.destination?.name ?? '',
          product: buildTrackEventProduct({
            houseId: requestedHouse.value.id,
            houseName: requestedHouse.value.name,
            iconic: Boolean(requestedHouse.value.iconicCollection),
            onlineBookingAvailable: Boolean(
              requestedHouse.value.onlineReservation,
            ),
          }),
        }
      : null
    trackEvent({
      category: 'General',
      event: 'meeting_inquiry_sent',
      source: stateScheduler.value.source,
      ...payload,
    })
  }

  return {
    closeScheduler,
    openScheduler,
    requestedHouse,
    schedulerIsOpen,
    trackSchedulerSuccess,
  }
}
