<script setup lang="ts">
const { trackEvent } = useTracking()
const { locale } = useI18n()

const networks = computed(
  () =>
    [
      {
        href: 'https://fr-fr.facebook.com/lecollectionist/',
        label: 'Facebook',
        icon: 'facebookNoir',
      },
      {
        href: 'https://www.instagram.com/lecollectionist/',
        label: 'Instagram',
        icon: 'instagramNoir',
      },
      {
        href: `https://${locale.value === 'fr' ? 'fr' : 'www'}.linkedin.com/company/le-collectionist`,
        label: 'Linkedin',
        icon: 'linkedinNoir',
      },
    ] as const,
)

const trackNavigationLink = (label: string) => {
  trackEvent({
    event: 'social_link_clicked',
    social_network: label,
  })
}
</script>

<template>
  <div class="flex w-full items-center gap-3">
    <template v-for="network in networks" :key="network.name">
      <BaseButton
        rel="nofollow noopener"
        target="_blank"
        variant="link"
        :aria-label="network.label"
        :href="network.href"
        @click="trackNavigationLink(network.label)"
      >
        <BaseIcon
          class="fill-gray-700 stroke-white"
          :name="network.icon"
          :size="1.5"
        />
      </BaseButton>
    </template>
  </div>
</template>
