<script setup lang="ts">
import type { MobileStore } from '~/types/types'

const config = useRuntimeConfig()
const { locale } = useI18n()
const { trackEvent, pageType } = useTracking()

const { source, sizeImage } = defineProps<{
  sizeImage: { height: number; width: number }
  source:
    | 'bank_imprint'
    | 'client_account_banner'
    | 'client_account_popin'
    | 'footer'
    | 'inquiry_updated'
    | 'login_signup_funnel'
}>()

const trackClick = (whichDevice: MobileStore) => {
  trackEvent({
    app_device: whichDevice,
    event: 'touchpoint_store',
    page_type: pageType.value,
    source,
  })
}
</script>

<template>
  <div class="flex gap-3">
    <NuxtLink
      :to="config.public.ownerAppIOS"
      external
      rel="nofollow noopener"
      target="_blank"
      @click="trackClick('app-store')"
    >
      <img
        :height="sizeImage.height"
        :src="`/stores/app-store-${locale}.svg`"
        :width="sizeImage.width"
        loading="lazy"
        alt="logo-apple-store"
      />
    </NuxtLink>
    <NuxtLink
      :to="config.public.ownerAppAndroid"
      external
      rel="nofollow noopener"
      target="_blank"
      @click="trackClick('google-play')"
    >
      <img
        :height="sizeImage.height"
        :src="`/stores/play-store-${locale}.svg`"
        :width="sizeImage.width"
        loading="lazy"
        alt="logo-play-store"
      />
    </NuxtLink>
  </div>
</template>
