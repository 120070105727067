import {
  Experiment as ExperimentBrowser,
  type ExperimentClient,
  type ExperimentConfig,
  type ExposureTrackingProvider,
  type Variants,
} from '@amplitude/experiment-js-client'
import type { RemoteEvaluationConfig } from '@amplitude/experiment-node-server'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const variants = useState<Variants>('amplitude:variants')
  const { experimentUser } = useExperimentCookies()

  let experimentClient = nuxtApp.$experimentClient as ExperimentClient

  const serverConfig: RemoteEvaluationConfig = {
    debug: config.public.appDomain !== 'production',
    fetchRetries: 3,
    serverUrl: 'https://api.lab.eu.amplitude.com',
    serverZone: 'eu',
  }
  const clientConfig: ExperimentConfig = {
    ...serverConfig,
    automaticExposureTracking: false,
    fallbackVariant: { value: 'control' },
    fetchOnStart: true,
    pollOnStart: false,
    retryFetchOnFailure: false,
  }

  const exposureTrackingProvider: ExposureTrackingProvider = {
    track: async ({ flag_key, variant }) => {
      try {
        const query = {
          flag_key,
          variant,
          ...experimentUser.value,
        }
        const exposure = await $fetch('/api/amplitude/expose', { query })
        if (exposure?.code !== 200) throw new Error(JSON.stringify(exposure))
      } catch (err) {
        console.error('experiment::exposure', err)
      }
    },
  }

  try {
    if (import.meta.server) {
      const { Experiment: ExperimentServer } = await import(
        '@amplitude/experiment-node-server'
      )
      variants.value = await ExperimentServer.initialize(
        config.public.amplitudeApiKey,
        serverConfig,
      ).fetchV2(experimentUser.value)
    } else {
      // in the client, we only want to create the ExperimentClient once
      experimentClient ||= ExperimentBrowser.initialize(
        config.public.amplitudeApiKey,
        {
          ...clientConfig,
          initialVariants: variants.value,
          exposureTrackingProvider,
        },
      )
      // watch the experiment user to refetch associated variants
      watch(experimentUser, async (newUser, oldUser) => {
        if (newUser.user_id !== oldUser?.user_id) {
          await experimentClient.fetch(newUser)
          variants.value = experimentClient.all()
        }
      })
      // start the experiment client in browser context
      await experimentClient.start(experimentUser.value)
    }
  } catch (err) {
    console.warn('experiment::client', err)
  }

  return {
    provide: {
      experimentClient,
    },
  }
})
