import type { StateMultipleWishlist } from '~/types/types'
import type {
  CreateWishlistInput,
  ModifyWishlistInput,
  WishlistFormatted,
} from '@/repository/entities/wishlist'

export const useMultipleWishlist = () => {
  const { $lcServicesWishlist, $httpCookies } = useNuxtApp()
  const { wishlistController, wishlistPresenter } = $lcServicesWishlist

  const stateMultipleWishlist = useState<StateMultipleWishlist>(
    'state-multiple-wishlist',
    () => ({
      fetched: false,
      lastWishlistTokenActive: '',
      wishlists: [],
    }),
  )

  const houseIdsAlreadyInFavorites = computed(() => {
    const houseIds = stateMultipleWishlist.value.wishlists.reduce<number[]>(
      (acc, curr) => {
        acc.push(...curr.houseIds)

        return acc
      },
      [],
    )

    return houseIds
  })

  const wishlists = computed(() =>
    stateMultipleWishlist.value.wishlists.sort(
      (a, b) => Number(b.id) - Number(a.id),
    ),
  )
  const fetched = computed(() => stateMultipleWishlist.value.fetched)
  const lastWishlistTokenActive = computed(
    () => stateMultipleWishlist.value.lastWishlistTokenActive,
  )
  const housesWishlistActiveInHeader = computed(() => {
    const wishlist = wishlists.value.find(
      (w) => w.privateToken === lastWishlistTokenActive.value,
    )

    return {
      number: wishlist?.houseCount || 0,
      exist: !!wishlist,
    }
  })

  const checkLastWishlistTokenActiveValid = computed(() =>
    wishlists.value.some(
      (w) => w.privateToken === lastWishlistTokenActive.value,
    ),
  )

  const fetchWishlists = async () => {
    await wishlistController.getUserWishlists()

    const res = wishlistPresenter.vm.wishlists

    clearWishlists()

    if (res?.length) {
      stateMultipleWishlist.value.wishlists.push(...res)

      const lastWishlistActive = $httpCookies.get('user_wishlist_token')
      if (lastWishlistActive) setLastWishlistTokenActive(lastWishlistActive)

      if (!checkLastWishlistTokenActiveValid.value && wishlists.value.length) {
        setLastWishlistTokenActive(wishlists.value[0].privateToken)
      }
    }

    stateMultipleWishlist.value.fetched = true
  }

  const clearWishlists = () => {
    stateMultipleWishlist.value = {
      ...stateMultipleWishlist.value,
      fetched: false,
      wishlists: [],
    }
  }

  const createWishlist = async (input: CreateWishlistInput) => {
    await wishlistController.createUserWishlist(input)

    const res = wishlistPresenter.vm.wishlists
    const wishlistCreated = res?.pop()

    if (wishlistCreated) {
      stateMultipleWishlist.value.wishlists.push(wishlistCreated)
    }

    return wishlistCreated || null
  }

  const deleteWishlist = async (token: string) => {
    await wishlistController.deleteUserWishlist({
      wishlistToken: token,
    })

    if (wishlistPresenter.vm.wishlistError) {
      throw wishlistPresenter.vm.wishlistError
    }

    stateMultipleWishlist.value.wishlists =
      stateMultipleWishlist.value.wishlists.filter(
        (w) => w.privateToken !== token,
      )

    clearLastWishlistTokenActive()

    return wishlistPresenter.vm.wishlistDeleted
  }

  const deleteHouseWishlist = async (houseId: number) => {
    await wishlistController.deleteUserHouseWishlist({ houseId })

    if (wishlistPresenter.vm.wishlistError) {
      throw wishlistPresenter.vm.wishlistError
    }

    await fetchWishlists()

    return wishlistPresenter.vm.wishlistHouseDeleted
  }

  const updateWishlist = async (input: ModifyWishlistInput) => {
    await wishlistController.modifyUserWishlist(input)

    if (wishlistPresenter.vm.wishlistError) {
      throw wishlistPresenter.vm.wishlistError
    }

    const wishlistUpdated = wishlistPresenter.vm.wishlistUpdated
    if (wishlistUpdated) {
      updateStateMultipleWishlist(wishlistUpdated)
    }

    return wishlistUpdated
  }

  const updateStateMultipleWishlist = (wishlistUpdated: WishlistFormatted) => {
    const wishlistIndexToModify =
      stateMultipleWishlist.value.wishlists.findIndex(
        (w) => w.privateToken === wishlistUpdated.privateToken,
      )

    if (wishlistIndexToModify > -1) {
      stateMultipleWishlist.value.wishlists[wishlistIndexToModify] =
        wishlistUpdated
    }
  }

  const addFavorite = (wishlistToken: string, houseId: number) => {
    const currentWishlist = stateMultipleWishlist.value.wishlists.find(
      (w) => w.privateToken === wishlistToken,
    )

    if (currentWishlist) {
      const houseIds = currentWishlist.houseIds
      houseIds.push(houseId)

      return updateWishlist({
        wishlistToken,
        house_ids: houseIds,
      })
    }
  }

  const removeFavorite = (wishlistToken: string, houseId: number) => {
    const currentWishlist = stateMultipleWishlist.value.wishlists.find(
      (w) => w.privateToken === wishlistToken,
    )

    if (currentWishlist) {
      const houseIds = currentWishlist.houseIds.filter((id) => id !== houseId)

      return updateWishlist({
        wishlistToken,
        house_ids: houseIds,
      })
    }
  }

  const setLastWishlistTokenActive = (token: string) => {
    stateMultipleWishlist.value.lastWishlistTokenActive = token
    $httpCookies.set('user_wishlist_token', token)
  }

  const clearLastWishlistTokenActive = () => {
    stateMultipleWishlist.value.lastWishlistTokenActive = ''
    $httpCookies.remove('user_wishlist_token')
  }

  return {
    addFavorite,
    clearWishlists,
    createWishlist,
    deleteHouseWishlist,
    deleteWishlist,
    fetched,
    fetchWishlists,
    houseIdsAlreadyInFavorites,
    housesWishlistActiveInHeader,
    lastWishlistTokenActive,
    removeFavorite,
    setLastWishlistTokenActive,
    updateWishlist,
    wishlists,
  }
}
