import {
  Mode,
  QueryModelType,
  Locale,
  SupportedCurrencies,
} from "../../../../../types";

import {
  SearchHousesWithDatesRepository,
  SearchRepository,
} from "./interfaces/SearchHousesWithDatesRepository";
import { SearchHousesPresentation } from "../interfaces/SearchHousesPresentation";
import { GetSearchParamsType } from "../types";

import { Search } from "../../../domain";
import { PRICES } from "../../../domain/helpers";
import { SearchType } from "../../../../../services/algolia";

export default class SearchHousesUseCaseWithDates extends Search {
  constructor(private repository: SearchHousesWithDatesRepository) {
    super();
  }

  async getSearchHousesInfo(
    input: GetSearchParamsType,
    deviceMode: "mobile" | "web",
    mode: Mode,
    repository: SearchRepository,
    locale?: Locale,
    algoliaFilters?: string
  ) {
    const searchParams = this.getSearchParams(
      input,
      deviceMode,
      mode,
      locale as Locale
    );
    const areResults =
      input.housesPeriodIds?.validHousePeriodsIds.length ||
      input.housesPeriodIds?.validHousePeriodsIds.length;

    return areResults
      ? await this.getBrowseResponse({
          ...searchParams,
          repository,
          mode,
          algoliaFilters,
        })
      : [];
  }

  private async getSearchFacets(
    input: GetSearchParamsType,
    deviceMode: "mobile" | "web",
    mode: Mode,
    repository: SearchRepository,
    locale?: Locale,
    algoliaFilters?: string
  ) {
    return await this.getSearchFacetsWithPeriod({
      input,
      mode,
      getSearchParams: () =>
        this.getSearchParams(input, deviceMode, mode, locale as Locale),
      locale,
      repository,
      algoliaFilters,
    });
  }

  async execute(
    presenter: SearchHousesPresentation,
    {
      bathrooms = "",
      bedrooms = "",
      capacity = "",
      destinationIds = [],
      facetFilters = new Map(),
      housesIds = [],
      initializelist = false,
      itemsPerPage = 15,
      mode = "client",
      page = 0,
      period,
      prices = [PRICES.MIN_PRICE_CHOICE, PRICES.MAX_PRICE_CHOICE],
      query = "",
      replica = "recommended",
      algoliaFilters = "",
    }: QueryModelType,
    deviceMode: "mobile" | "web" = "web",
    locale: Locale,
    currency: SupportedCurrencies | null,
    algoliaIds: SearchType
  ) {
    try {
      const { getHousePeriodsIds, getHousePeriods, algolia } = this.repository;
      const repository = {
        getHousePeriodsIds,
        getHousePeriods,
        algolia: algolia(algoliaIds),
      };

      this.setCurrency(currency || undefined);

      const input = {
        bathrooms,
        bedrooms,
        capacity,
        currency: this.currency,
        destinationIds,
        facetFilters,
        housesIds,
        initializelist,
        itemsPerPage,
        maxPrice: prices[1] || PRICES.MAX_PRICE_CHOICE,
        minPrice: prices[0] || PRICES.MIN_PRICE_CHOICE,
        mode,
        page,
        period,
        query,
        replicaValue: replica,
        algoliaFilters,
      };

      this.setCurrentPage(page);
      this.setHitsPerPage(itemsPerPage);
      this.setCurrency(this.currency);
      this.setPeriod(period);
      this.setLocale(locale);

      if (initializelist) {
        this.initializeList();
        this.setCurrentPage(page);

        const housePeriodIdsResponse = await this.getHouseIdsPeriodResponse({
          currency: this.currency,
          destinationIds,
          deviceMode,
          housesIds,
          maxBudget: prices[1] as number,
          minBudget: prices[0] as number,
          period,
          repository,
          sortBy: replica,
        });
        const housesPeriodIds = housePeriodIdsResponse?.data;
        if (housesPeriodIds) presenter.displayHousesPeriodIds(housesPeriodIds);

        const hits = await this.getSearchHousesInfo(
          { ...input, housesPeriodIds },
          deviceMode,
          mode,
          repository,
          locale,
          algoliaFilters
        );

        const hitsWithPeriodPrices = destinationIds.length
          ? await this.getHousePeriodsResponse({
              deviceMode,
              currency: this.currency,
              destinationIds,
              hits,
              maxBudget: prices[1] as number,
              minBudget: prices[0] as number,
              period,
              repository,
              sortBy: replica,
            })
          : [];

        const newHits = hitsWithPeriodPrices.length
          ? hitsWithPeriodPrices
          : hits;

        this.facetsFilters = await this.getSearchFacets(
          { ...input, housesPeriodIds },
          deviceMode,
          mode,
          repository,
          locale,
          algoliaFilters
        );

        const sortedValidHits = this.sortHits(
          newHits,
          this.validHousesIds,
          "valid"
        );
        const sortedInvalidHits = this.sortHits(
          newHits,
          this.invalidHousesIds,
          "invalid"
        );
        const formatInvalidHits = this.formatInvalidHits(sortedInvalidHits);
        const sortedAllHits = [...sortedValidHits, ...formatInvalidHits];
        this.setSortedHits(sortedAllHits);
        this.chunkedHits = this.chunkHits(sortedAllHits);
        this.setNbHits(newHits.length);
        this.setNbPages(this.chunkedHits.length);
        presenter.displayFacets(this.facetsFilters);
        presenter.displayNbPages(this.nbPages);
        presenter.displayNbHouses(this.nbHits);
        presenter.displayAlgoliaQueryId();
      }

      presenter.displaySearchCurrentPage(this.currentPage);

      const hitsWithPeriodPrices = destinationIds.length
        ? this.getHits(housesIds)
        : await this.getHousePeriodsResponse({
            deviceMode,
            currency: this.currency,
            destinationIds,
            hits: this.getHits(housesIds),
            maxBudget: prices[1] as number,
            minBudget: prices[0] as number,
            period,
            repository,
            sortBy: replica,
          });

      if (this.canPaginate()) {
        this.currentHits = [...this.currentHits, ...hitsWithPeriodPrices];

        presenter.displaySearchHousesResult(this.currentHits);
      }
    } catch (err) {
      console.log("SearchHousesUseCaseWithDates", err);
    }
  }
}
