<script setup lang="ts">
import { currencies } from '@/helpers/priceHelper'
import type { TheHeaderSwitchContent } from '#build/components'

const {
  areMenuItemsWhite = false,
  position = 'right-0 top-[calc(100%+1.5rem)]',
} = defineProps<{
  areMenuItemsWhite?: boolean
  position?: string
}>()

const { isMobile } = useBreakpoint()
const { currency } = useUser()

const switchRef = ref<InstanceType<typeof TheHeaderSwitchContent> | null>(null)
const isOpen = ref(false)

const userCurrencySymbol = computed(
  () => currencies.find((c) => c.code === currency.value)?.symbol,
)

const reset = () => {
  if (isOpen.value) isOpen.value = false
}

onMounted(() => {
  if (!isMobile.value) document.addEventListener('scroll', reset)
})
onUnmounted(() => {
  document.removeEventListener('scroll', reset)
})
</script>

<template>
  <div class="relative mx-4 flex h-full">
    <button
      :class="['flex items-center', { 'text-white': areMenuItemsWhite }]"
      type="button"
      @click="isOpen = true"
    >
      <div class="mr-3.5 flex items-center text-md">
        {{ $i18n.locale.toUpperCase() }}
        -
        {{ userCurrencySymbol }}
      </div>

      <BaseIcon :class="{ 'rotate-180': isOpen }" name="navArrowDown" />
    </button>

    <BaseModalV2
      v-if="isMobile"
      v-model="isOpen"
      :primary-button="$t('global.validate')"
      @primary-button="() => switchRef?.handleValidate()"
    >
      <template #content>
        <div>
          <TheHeaderSwitchContent
            ref="switchRef"
            hide-validate-button
            @validated="reset"
          />
        </div>
      </template>
    </BaseModalV2>

    <transition v-else name="fade">
      <div
        v-if="isOpen"
        :class="[
          'absolute mt-2 w-[92vw] rounded border border-gray-200 bg-white p-6 shadow-lg sm:w-[380px]',
          position,
        ]"
      >
        <TheHeaderSwitchContent @validated="reset" />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}

.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
