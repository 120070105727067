import { SearchDestinationsRepository } from "./interfaces/SearchDestinationsRepository";
import { SearchDestinationsPresentation } from "./interfaces/SearchDestinationsPresentation";
import { SearchType } from "services/algolia";
import { SearchDestinations } from "types";

export default class SearchDestinationsUseCase {
  constructor(private repository: SearchDestinationsRepository) {}

  async execute(
    algoliaIds: SearchType,
    presenter: SearchDestinationsPresentation
  ) {
    const alogliaRepo = this.repository.algolia(algoliaIds);

    const hits = await alogliaRepo.browseQuery<SearchDestinations>({
      indexName: "public_destinations",
      query: "",
      params: {
        facetFilters: [],
        numericFilters: [],
        attributesToRetrieve: [
          "child_ids",
          "clusterized_name",
          "first_photo_url",
          "houses_count",
          "id",
          "is_country",
          "name",
          "parent_id",
        ],
        filters: "NOT houses_count=0",
      },
    });

    if (hits?.length > 0) {
      presenter.displayDestinations(hits);
    }
  }
}
