import { defineHeader } from '~/helpers/defineHeader'
import { transparentPagesNames } from '~/utils/headerTheme'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { hook } = useNuxtApp()
  const { setCustomPageTheme, setTemporaryTheme, setRoute, setUrlFrom } =
    useHeaderNavigation()

  if (from?.fullPath) setUrlFrom(from.fullPath)
  if (to.name) {
    hook('page:finish', () => {
      const routeName = to.name.slice(0, -5)
      const isLp = to.meta?.destinationPage?.isLp || false
      let color = transparentPagesNames.includes(routeName)
        ? 'transparent'
        : 'white'

      if (routeName === 'destination-name') {
        color = isLp ? 'transparent' : 'white'
      }

      setRoute(to.name)
      setTemporaryTheme(color)
      setCustomPageTheme(color)
    })
  }

  defineHeader({ routeName: to.name })
})
