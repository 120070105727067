import {
  ApiAuthError,
  RemoveSessionResponse,
  UserInformationResponse,
} from "../../../../types";
import { SignInWithEmailPresentation } from "../../usecases/SignInWithEmail/interfaces/SignInWithEmailPresentation";
import { TokenAuthPresentation } from "../../usecases/TokenAuth/interfaces/TokenAuthPresentation";
import { SignUpPresentation } from "../../usecases/SignUp/interfaces/SignUpPresentation";
import { RemoveUserSessionPresentation } from "../../usecases/RemoveUserSession/interfaces/RemoveUserSessionPresentation";
import { NavType } from "modules/auth/adapters/ViewModel";

const setSignUpInfo = (
  presenter: TokenAuthPresentation,
  data: Required<UserInformationResponse>["data"]
) => {
  presenter.displayMessage("loginWithToken.success");
  presenter.displayNavigation("SignUp");
  presenter.displayTokenSignIn(data);
};

export const dispatch = {
  emailTokenSignIn: (
    emailResponse: UserInformationResponse,
    presenter: TokenAuthPresentation,
    redirect?: NavType
  ) => {
    if (emailResponse.data) {
      const {
        civility,
        firstName,
        incompleteSignup,
        lastName,
        termsOfServiceSigned,
        termsOfServiceSignedAt,
        invitationCreatedAt,
        phone,
      } = emailResponse.data;

      const identityMissed = !firstName || !lastName || !civility;
      const termsOfServicesCondition =
        !termsOfServiceSigned && !termsOfServiceSignedAt && invitationCreatedAt;

      if (termsOfServicesCondition) {
        setSignUpInfo(presenter, emailResponse.data);
      } else if (incompleteSignup && identityMissed) {
        setSignUpInfo(presenter, emailResponse.data);
      } else if (!phone) {
        presenter.displayMessage("PhoneUpdateWithToken.success");
        presenter.displayNavigation("UserPhoneUpdate");
      } else {
        presenter.displayMessage("loginWithToken.success");
        presenter.displayNavigation(redirect || "App");
        presenter.displayTokenSignIn(emailResponse.data);
      }
    } else if (emailResponse.errors) {
      presenter.displayErrors({
        fieldName: "phone",
        error: "phone.wrongToken",
      });
    }
  },
  emailTokenSignUp: (
    signUpResponse: UserInformationResponse,
    presenter: TokenAuthPresentation
  ) => {
    if (signUpResponse.data) {
      presenter.displayMessage("SignUpWithToken.success");
      presenter.displayNavigation("SignUp");
      presenter.displayTokenSignUp(signUpResponse.data);
    } else if (signUpResponse.errors) {
      presenter.displayErrors({
        fieldName: "phone",
        error: "phone.wrongToken",
      });
    }
  },
  getTokenFromEmail: (
    presenter: SignInWithEmailPresentation,
    emailResponse: boolean
  ) => {
    presenter.displayMessage("verifyEmailLink.success");
    presenter.displayNavigation("Token");
    presenter.displaySignIn(emailResponse);
  },
  removeSession: (
    removeUserSessionResponse: RemoveSessionResponse,
    presenter: RemoveUserSessionPresentation
  ) => {
    if (removeUserSessionResponse) {
      presenter.displayMessage("logout.success");
      presenter.displayNavigation("EmailAuth");
    }
  },
  signUp: (
    signUpResponse: UserInformationResponse,
    presenter: SignUpPresentation,
    redirect?: NavType
  ) => {
    if (signUpResponse?.errors) {
      const phoneError = signUpResponse.errors as unknown as ApiAuthError;
      const phoneErrorFormatted =
        phoneError?.response?.data?.errors[0]?.user?.phone?.[0]?.error;
      if (phoneErrorFormatted === "taken") {
        presenter.displayErrors({
          fieldName: "phone",
          error: "phone.phoneTaken",
        });
      } else {
        presenter.displayErrors({
          fieldName: "phone",
          error: "phone.phoneFormatHasNotCorrect",
        });
      }
    } else if (signUpResponse.data) {
      presenter.displayUser(signUpResponse.data);
      presenter.displayNavigation(redirect || "App");
      presenter.displayMessage("signUp.accountSuccessfullCreated");
    }
  },
  signUpFromEmail: (
    presenter: SignInWithEmailPresentation,
    signUpResponseSuccess?: boolean,
    signupErrorInvalidEmail?: {
      fieldName: string;
      error: string;
    }
  ) => {
    if (
      signUpResponseSuccess ||
      signupErrorInvalidEmail?.error === "incompleteSignup"
    ) {
      presenter.displayMessage("emailSignUp.success");
      presenter.displayNavigation("Token");
    } else if (signupErrorInvalidEmail) {
      presenter.displayErrors(signupErrorInvalidEmail);
    } else {
      presenter.displayNavigation("SignUp");
    }
  },
};
