<script setup lang="ts">
const { isDesktopOrTablet } = useBreakpoint()
const { links = [] } = defineProps<{
  links?: { href: string; label: string; icon?: IconPath }[]
  title: string
}>()

const isOpen = ref(isDesktopOrTablet.value)
const icon = computed(() =>
  isDesktopOrTablet.value ? false : isOpen.value ? 'minus' : 'plus',
)

watch(isDesktopOrTablet, (val) => (isOpen.value = val), {
  immediate: import.meta.client,
})
</script>

<template>
  <div class="flex w-full flex-col py-1 sm:w-1/4 sm:py-3 lg:pt-0">
    <BaseCollapse
      v-model="isOpen"
      :button-attrs="{
        class:
          'font-monospace !text-gray-700 text-xl justify-between !w-full !mb-4',
      }"
      :button-text="{ open: title, close: title }"
      :icon="icon"
    >
      <TheFooterSimpleLink
        v-for="link in links"
        :key="link.href"
        class="mb-3 text-md text-gray-600"
        :href="link.href"
      >
        <BaseIcon v-if="link.icon" :name="link.icon" :size="1" />
        {{ link.label }}
      </TheFooterSimpleLink>
    </BaseCollapse>
  </div>
</template>

<style scoped>
:deep(.lc-collapse-icon) {
  @apply !w-6 !h-6;
}
</style>
