import { v4 } from 'uuid'

export const useExperimentCookies = () => {
  const AMP_DEVICE_ID_COOKIE = '_amp'
  const EXPERIMENT_DEBUG_COOKIE = 'experiment_debug'

  const {
    public: { appDomain },
  } = useRuntimeConfig()
  const { $httpCookies } = useNuxtApp()
  const { authUserIdTracking } = useAuth()

  // Debug Cookie
  const isDebugging = useCookie(EXPERIMENT_DEBUG_COOKIE, {
    default: () => {
      const existing = $httpCookies.get(EXPERIMENT_DEBUG_COOKIE)
      if (existing) return existing
      const value = ['development', 'staging', 'reviews'].includes(appDomain)
      $httpCookies.set(EXPERIMENT_DEBUG_COOKIE, value)
      return value
    },
  })
  /**
   * Device ID cookie used by the Amplitude Analytics SDK & Experiment SDK
   * - created or retrieved server-side to fetch variants from the Experiment SDK
   * - retrieved client-side via the GTM configuration of the Analytics SDK (see the "Amplitude - Init SDK" tag in GTM)
   *
   * The device ID must be known during the initialization phase of both SDKs so that:
   * - there is only 1 session sent by the Analytics SDK
   * - event-tracking works alongside experiment-tracking
   */
  const amplitudeDeviceId = useCookie<string>(AMP_DEVICE_ID_COOKIE, {
    default: () => {
      const value = $httpCookies.get(AMP_DEVICE_ID_COOKIE)
      if (value) return value
      const randomUUID = v4()
      $httpCookies.set(AMP_DEVICE_ID_COOKIE, randomUUID)
      return randomUUID
    },
  })

  const experimentUser = computed(() => ({
    device_id: amplitudeDeviceId.value,
    ...(authUserIdTracking.value ? { user_id: authUserIdTracking.value } : {}),
  }))

  return {
    amplitudeDeviceId,
    experimentUser,
    isDebugging,
  }
}
