export const useLockBody = () => {
  const state = useState('lock-body', () => ({ pageYOffset: 0, locked: false }))

  const pageYOffset = computed(() => state.value.pageYOffset)
  const locked = computed(() => state.value.locked)

  const setLock = (lock: boolean) => {
    state.value.locked = lock

    if (lock) {
      state.value.pageYOffset = window.pageYOffset

      document.body.style.marginTop = `-${pageYOffset.value}px`
      document.body.classList.add(
        'fixed',
        'left-0',
        'right-0',
        'overflow-hidden',
      )
    } else {
      document.body.style.marginTop = ''
      document.body.classList.remove(
        'fixed',
        'left-0',
        'right-0',
        'overflow-hidden',
      )

      window.scrollTo(0, pageYOffset.value)
    }
  }

  return { setLock, pageYOffset, locked }
}
