<script setup lang="ts">
import type { SupportedCurrencies } from 'lc-services/types'
const { locales, locale } = useI18n()
const { getNewUrl } = useSetRouteParams()
const { currency, setCurrency } = useUser()

const currencies = ref<{ symbol?: string; value: SupportedCurrencies }[]>([
  {
    symbol: '€',
    value: 'EUR',
  },
  {
    value: 'CHF',
  },
  {
    symbol: '£',
    value: 'GBP',
  },
  {
    symbol: '$',
    value: 'USD',
  },
])

const currencyActive = computed(() => currency.value)

const changeCurrency = (value: SupportedCurrencies) => {
  setCurrency(value)
}
</script>

<template>
  <div class="max-w-xs">
    <div class="font-monospace mb-6 text-4xl">
      {{ $t('header.language') }}
    </div>

    <ul class="mb-12 flex flex-wrap">
      <li v-for="l in locales" :key="l.code" class="mr-8">
        <a
          :class="[
            'flex items-center rounded-3xl border px-6 py-3 uppercase outline-none hover:no-underline focus:outline-none',
            {
              'border-gray-700 font-medium text-gray-700 hover:text-gray-700':
                l.code === locale,
            },
            {
              'border-gray-300 text-gray-400 hover:text-gray-400':
                l.code !== locale,
            },
          ]"
          :href="getNewUrl(l.code)"
        >
          <img
            :src="`https://cdn.lecollectionist.com/lc/production/assets/images/flags/${l.code}.svg`"
            :alt="l.code"
            class="mr-4 w-6"
          />
          {{ l.code }}
        </a>
      </li>
    </ul>

    <div class="font-monospace mb-6 text-4xl">
      {{ $t('header.currency') }}
    </div>

    <ul class="m-0 flex flex-wrap">
      <li v-for="cu in currencies" :key="cu.value" class="mb-10 mr-8">
        <button
          :class="[
            'flex items-center rounded-3xl border px-6 py-3 outline-none focus:outline-none',
            {
              'border-gray-700 font-medium text-gray-700':
                cu.value === currencyActive,
            },
            {
              'border-gray-300 text-gray-400': cu.value !== currencyActive,
            },
          ]"
          type="button"
          :data-testid="`currency-${cu.value}`"
          @click="changeCurrency(cu.value)"
        >
          <template v-if="cu.symbol">
            {{ cu.symbol }}
          </template>
          {{ cu.value }}
        </button>
      </li>
    </ul>
  </div>
</template>
