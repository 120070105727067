import { phoneByLanguage } from '@/helpers/getPhoneByLanguage'

export const useContactPhoneNumber = () => {
  const config = useRuntimeConfig()
  const { user } = useUser()
  const browserLanguage = useState<string>('browserLanguage')

  const phoneNumber = computed(() =>
    phoneByLanguage({
      config,
      language: user.value.country || browserLanguage.value,
    }),
  )
  return { phoneNumber }
}
