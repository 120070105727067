import type { RuntimeConfig } from '@nuxt/schema'

export const phoneByLanguage = ({
  config,
  language,
}: {
  config: RuntimeConfig
  language?: string | null
}) => {
  const safeLanguage = (language ?? '').slice(0, 2).toUpperCase()

  switch (safeLanguage) {
    case 'BE':
      return config.public.collectionistPhoneBe
    case 'CH':
      return config.public.collectionistPhoneCh
    case 'EN':
      return config.public.collectionistPhoneFr
    case 'FR':
      return config.public.collectionistPhoneFr
    case 'GB':
      return config.public.collectionistPhoneGb
    case 'UK':
      return config.public.collectionistPhoneGb
    case 'US':
      return config.public.collectionistPhoneUs
    default:
      return config.public.collectionistPhoneFr
  }
}
