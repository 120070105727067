/** *   CURRENCY SETTER 💰   ***/

/*
  Gets the user's currency choice if there's one,
  or sets it as a cookie based on the browser's language
*/
export default defineNuxtPlugin(() => {
  const { currency, setCurrency } = useUser()

  const browserLanguage = useState('browserLanguage')

  const getCurrencyFromBrowser = () => {
    let newCurrency = ''

    switch (browserLanguage.value) {
      case 'US':
        newCurrency = 'USD'
        break
      case 'GB':
        newCurrency = 'GBP'
        break
      case 'CH':
        newCurrency = 'CHF'
        break
      default:
        newCurrency = 'EUR'
        break
    }

    return newCurrency
  }

  if (currency.value) {
    setCurrency(currency.value)
  } else {
    setCurrency(getCurrencyFromBrowser())
  }
})
