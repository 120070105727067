import type { Locale } from 'lc-services/types'

export const useCallCenterSchedule = () => {
  const { locale } = useI18n<unknown, Locale>()

  const startTimeWeek = computed(() =>
    convertTimeByTimezone({ time: '9:30', locale: locale.value }),
  )
  const endTimeWeek = computed(() =>
    convertTimeByTimezone({ time: '22:30', locale: locale.value }),
  )
  const saturdayEndTimeWeek = computed(() =>
    convertTimeByTimezone({ time: '18:30', locale: locale.value }),
  )

  return { startTimeWeek, endTimeWeek, saturdayEndTimeWeek }
}
