import { $dayjs, setDayjsLocale } from './dayjs'
import type { ConfigType } from 'dayjs'
import type { Locale } from 'lc-services/types'

export const formatDate = (date: string, format = 'YYYY-MM-DD') =>
  $dayjs(date, 'YYYY-MM-DD').format(format)

export const formatTime = (time: string, locale: Locale) => {
  if (locale === 'fr') return time.split(':').join('h')
  const [hour, minute] = time.split(':').map(Number)
  const meridiem = hour / 12 >= 1 ? ' PM' : ' AM'
  if (hour === 0 || hour === 12)
    return `12:${String(minute).padStart(2, '0')}${meridiem}`
  return [hour % 12, String(minute).padStart(2, '0')].join(':').concat(meridiem)
}

export const convertTimeByTimezone = ({
  time,
  locale,
}: {
  time: string
  locale: Locale
}) => {
  const parisTimeZone = 'Europe/Paris'
  const currentTime = new Date()

  const parisTime = new Intl.DateTimeFormat('en-US', {
    timeZone: parisTimeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(currentTime)

  const utcTime = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(currentTime)

  const [parisHours] = parisTime.split(':').map(Number)
  const [utcHours] = utcTime.split(':').map(Number)

  const diffBetweenParisAndUTC = (utcHours || 0) - (parisHours || 0)

  const [hours, minutes] = time.split(':').map(Number)

  const now = new Date()
  const utcDate = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      (hours || 0) + diffBetweenParisAndUTC,
      minutes,
    ),
  )

  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: locale !== 'fr',
  })
    .format(utcDate)
    .replace('24:', '00:')
    .replace(':', locale === 'fr' ? 'h' : ':')
}

export const formatShortPeriod = (
  period: [string, string],
  locale = 'en',
  format = 'YYYY-MM-DD',
) => {
  setDayjsLocale(locale)
  const startDate = $dayjs(period[0], format)
  const endDate = $dayjs(period[1], format)
  const localeFormat = locale === 'en' ? 'MMM D' : 'D MMM'
  const startFormat = `${localeFormat}${startDate.isSame(endDate, 'year') ? '' : ', YYYY'}`
  return `${startDate.format(startFormat)} - ${endDate.format(`${localeFormat}, YYYY`)}`
}

export const formatShortestPeriod = (
  period: [string, string],
  format = 'YYYY-MM-DD',
) => {
  const startDate = $dayjs(period[0], { format })
  const endDate = $dayjs(period[1], { format })
  const startFormat = 'D'.concat(
    startDate.isSame(endDate, 'month') ? '' : ' MMM',
  )
  return `${startDate.format(startFormat)}-${endDate.format('D MMM')}`
}

export const formatCompactPeriod = (
  period: [ConfigType, ConfigType],
  i18n: { locale: MaybeRef<string>; t: (...args: any[]) => string },
  format = 'YYYY-MM-DD',
) => {
  const locale = unref(i18n.locale)
  setDayjsLocale(locale)
  const startDate = $dayjs(period[0], { format, locale })
  const endDate = $dayjs(period[1], { format, locale })
  const startFormat = 'D'.concat(
    startDate.isSame(endDate, 'month') ? '' : ' MMM',
  )
  const endFormat = 'D MMM'.concat(
    startDate.isSame(endDate, 'year') ? '' : ' YYYY',
  )
  return i18n.t('global.dateRange', {
    start: startDate.format(startFormat),
    end: endDate.format(endFormat),
  })
}

export const validatePeriodAgainstBookedDates = (
  value: { startDate?: string | null; endDate?: string | null },
  bookedDates: string[] = [],
) => {
  // 1. check if the selected period overlaps the bookedDates
  if (value.startDate && value.endDate) {
    const startDate = $dayjs(value.startDate)
    return (
      // add 1 to include the end date in the period...
      new Array($dayjs(value.endDate).diff(startDate, 'day') + 1)
        .fill(startDate)
        .map((d, i) => d.add(i, 'day').format('YYYY-MM-DD'))
        // ...as bookedDates doesn't include checkin/checkout dates
        .every((d) => !bookedDates.includes(d))
    )
  }
  // 2. otherwise check each date individually
  if (value.startDate || value.endDate) {
    return ![value.startDate, value.endDate]
      .filter(Boolean)
      .some((d) => bookedDates.includes($dayjs(d).format('YYYY-MM-DD')))
  }
  return true
}
