import type { ExperimentUser, Variants } from '@amplitude/experiment-js-client'
import type { ExperimentKey } from '~/types/amplitude/types'

export const useExperiment = (...flagKeys: ExperimentKey[]) => {
  const { $i18n, $experimentClient } = useNuxtApp()
  const variants = useState<Variants>('amplitude:variants')
  const isDebugging = useCookie<boolean>('experiment_debug')

  const variant = (flagKey?: ExperimentKey) =>
    variants.value[flagKey || flagKeys[0]]?.value ?? 'control'

  const $tabx = (
    keypath: string,
    named: Record<string, unknown> = {},
  ): string => {
    const [flagKey, ...rest] = keypath.split('.') as [
      ExperimentKey,
      ...string[],
    ]
    return $i18n.t(
      `amplitude.${flagKey}.${variant(flagKey)}.${rest.join('.')}`,
      named,
    )
  }

  const experiments = computed(
    () => Object.keys(variants.value) as ExperimentKey[],
  )

  const isTreatment = computed(() =>
    flagKeys.every((key) => variant(key) === 'treatment'),
  )

  const changeVariant = (flagKey: ExperimentKey) => {
    if (isDebugging.value) {
      const value = variant(flagKey) === 'control' ? 'treatment' : 'control'
      variants.value = {
        ...variants.value,
        [flagKey]: { ...variants.value[flagKey], value },
      }
    }
  }

  const refetch = async (user?: ExperimentUser) => {
    if ($experimentClient) {
      try {
        await $experimentClient.fetch(user)
        variants.value = $experimentClient.all()
      } catch (err) {
        console.warn('experiment::refetch', err)
      }
    }
  }

  /**
   * @name tabx (= t + AB eXperiment)
   * @description A implementation of $i18n.t targeting Experiment A/B testing labels
   * @param keypath {String} a path to the requested translation value
   *  - MUST start with a flag_key
   *  - MUST NOT start with 'amplitude' (reserved namespace)
   *  - MUST NOT contain variant key (control or treatment)
   * @param named {Object} options to forward to `$i18n.t`
   * @example
   *  const { tabx } = useExperiment()
   *  const headerTitle = computed(() => tabx('wishlist-summary.header.title', { villaName: 'Casa Enea' }))
   * @example with a default flag_key
   *  const { tabx } = useExperiment('wishlist-summary')
   *  const headerTitle = computed(() => tabx('header.title', { villaName: 'Casa Enea' }))
   */
  const tabx = (keypath: string, named: Record<string, unknown> = {}) => {
    for (const flagKey of flagKeys) {
      if (keypath.startsWith(flagKey)) return $tabx(keypath, named)
    }
    if (flagKeys[0]) return $tabx(`${flagKeys[0]}.${keypath}`, named)
    return $tabx(keypath, named)
  }

  return {
    changeVariant,
    experiments,
    isTreatment,
    refetch,
    tabx,
    variant,
    variants,
  }
}
