import { useGtm } from '@gtm-support/vue-gtm'
import { hashDataAndSetForGtm } from '~/utils/hashDataAndSetForGtm'

export const useTracking = () => {
  const { $i18n } = useNuxtApp()
  const gtm = useGtm()
  const route = useRoute()
  const { authUserIdTracking, authenticated } = useAuth()
  const { currency, userType } = useUser()

  const pageType = computed(
    () =>
      (route.meta?.gtmAdditionalEventData as Record<string, string> | undefined)
        ?.page_type || 'other',
  )

  // TRACKEVENT
  const trackEvent = async ({
    category,
    event,
    label,
    hash,
    ...rest
  }: {
    category?: string
    event: string
    label?: string | Record<string, string>
    hash?: {
      email?: string
      phone?: string
    }
    [x: string]: unknown
  }) => {
    if (hash && Object.values(hash).length > 0) {
      await hashDataAndSetForGtm(hash)
    }

    let labelProperties = label || ''

    if (typeof labelProperties === 'object') {
      const keys = Object.keys(labelProperties)
      const result = keys.map((key) => {
        return `${key}:${labelProperties[key as keyof typeof labelProperties]}`
      })

      labelProperties = result.join(', ') as unknown as Record<string, string>
    }

    // GTM
    if (gtm) {
      gtm.push({
        category,
        event,
        label: labelProperties,
        ...rest,
      })
    }
  }

  const trackPageViewErrorPage = () => {
    if (gtm) {
      gtm.push({
        currency: currency.value,
        'content-name': route.fullPath,
        event: 'page_view',
        locale: $i18n.locale.value,
        logged_in: authenticated.value,
        page_type: 'error 404',
        user_lc_id: authUserIdTracking.value,
        user_type: userType.value,
      })
    }
  }

  return {
    pageType,
    trackEvent,
    trackPageViewErrorPage,
  }
}
