import type { CredentialResponseType } from '~/middleware/auth-provider.global'
import type { Auth } from '~/types/types'

const DEFAULT_AUTH_STATE = {
  authenticated: false,
  user: {},
} as Auth

const AUTH_COOKIES = ['access-token', 'client', 'uid']
const ALL_COOKIES = [
  ...AUTH_COOKIES,
  'search_admin_token',
  'search_b2b2c_token',
  'user_wishlist_token',
  'user_wishlist_dates',
]

export const useAuth = () => {
  const { $api, $lcRepositories, $httpCookies } = useNuxtApp()
  const {
    public: {
      algoliaSearchKey: defaultAlgoliaKey,
      algoliaId: appId,
      algoliaIndexPrefix,
    },
  } = useRuntimeConfig()
  const localePath = useLocalePath()
  const auth = useState<Auth & { signUp?: boolean }>(
    'auth',
    () => DEFAULT_AUTH_STATE,
  )
  const credentials = useState<CredentialResponseType | null>(
    'credentials',
    () => null,
  )
  const { fetchUserInformations, clearUser } = useUser()
  const { fetchProperties } = useUserHouses()
  const { fetchWishlists, clearWishlists } = useMultipleWishlist()
  const authenticated = computed(() => auth.value.authenticated ?? false)
  const signUp = computed(() => auth.value.signUp ?? false)
  const authUserId = computed(() => auth.value.user.id ?? null)
  const authUserIdTracking = computed(() =>
    authUserId.value ? `user_${authUserId.value}` : undefined,
  )
  const userIsAdmin = computed(() => auth.value.user.isAdmin ?? false)
  const userIsPartner = computed(
    () => auth.value.user.partnershipAgency ?? false,
  )
  const userIsOwner = computed(() =>
    Boolean(auth.value.user?.ownedHousesIds?.length),
  )

  const unsignedClientContracts = computed(
    () => auth.value.user.unsignedClientContracts ?? [],
  )
  const unsignedOwnerContracts = computed(
    () => auth.value.user.unsignedOwnerContracts ?? [],
  )
  const algoliaIds = computed(() => {
    let apiKey = defaultAlgoliaKey
    if (userIsAdmin.value && auth.value.user.algoliaAdminSearchKey) {
      apiKey = auth.value.user.algoliaAdminSearchKey
    }
    if (userIsPartner.value && auth.value.user.algoliaB2b2cSearchKey) {
      apiKey = auth.value.user.algoliaB2b2cSearchKey
    }
    return {
      apiKey,
      appId,
      prefix: algoliaIndexPrefix as 'staging' | 'production',
    }
  })

  const setHeaders = () => {
    $api.setHeaders(
      Object.fromEntries(
        AUTH_COOKIES.map((cookie) => [cookie, $httpCookies.get(cookie)]),
      ),
    )
    $lcRepositories.headers.setHeaders({
      accessToken: $httpCookies.get('access-token'),
      client: $httpCookies.get('client'),
      uid: $httpCookies.get('uid'),
    })
  }

  const clearHeaders = () => {
    $api.setHeaders(
      Object.fromEntries(AUTH_COOKIES.map((cookie) => [cookie, ''])),
    )
    $lcRepositories.headers.clearHeaders()
  }

  const resetAuth = (payload?: Auth & { signUp?: boolean }) => {
    auth.value = payload?.authenticated ? payload : DEFAULT_AUTH_STATE
    if (payload?.authenticated) setHeaders()
    else {
      ALL_COOKIES.forEach((cookie) => $httpCookies.remove(cookie))
      clearHeaders()
    }
  }

  const signContract = (
    contractId: string,
    flowType: 'unsignedClientContracts' | 'unsignedOwnerContracts',
  ) => {
    resetAuth({
      authenticated: authenticated.value,
      user: {
        ...auth.value.user,
        [flowType]: (auth.value.user[flowType] ?? []).filter(
          (id) => id.toString() !== contractId,
        ),
      },
    })
  }

  const login = async (user: Auth['user']) => {
    resetAuth({ authenticated: Boolean(user), user })
    await Promise.all([
      fetchUserInformations(),
      fetchProperties(),
      fetchWishlists(),
    ])
  }

  const setAuthenticated = (value: boolean) => {
    auth.value.authenticated = value
  }

  const setSignUp = (value: boolean) => {
    auth.value.signUp = value
  }

  const setCredentials = (data: CredentialResponseType) => {
    credentials.value = data
  }

  const logout = async (
    payload: { redirect: boolean; returnTo?: string } = { redirect: false },
  ) => {
    if (authenticated.value) await $api.v1.auth.signOut().catch(console.warn)
    resetAuth()
    credentials.value = null
    clearUser()
    clearWishlists()

    if (payload.redirect) {
      const path = localePath({
        name: 'login',
        ...(payload.returnTo
          ? { query: { return_to: payload.returnTo } }
          : null),
      })
      return navigateTo(path)
    }
  }

  return {
    algoliaIds,
    authenticated,
    authUserIdTracking,
    clearHeaders,
    credentials,
    login,
    logout,
    resetAuth,
    setAuthenticated,
    setCredentials,
    setHeaders,
    setSignUp,
    signContract,
    signUp,
    unsignedClientContracts,
    unsignedOwnerContracts,
    userIsAdmin,
    userIsOwner,
    userIsPartner,
  }
}
