<script setup lang="ts">
import { DatePicker } from 'v-calendar'

const { availabilities } = defineProps<{
  availabilities: Record<number, number[]>
  availableDurations: number[]
  disabledDates: number[]
  maxDate?: Date
  step: number
}>()
const emits = defineEmits<{
  'change-step': [number]
  'change-page': [{ month: number; year: number }]
}>()

const duration = defineModel<number>('duration')
const selectedDate = defineModel<string>('selectedDate')
const startTime = defineModel<number>('startTime')
const timezone = defineModel<string>('timezone')

const { isMobile } = useBreakpoint()
const { locale, t } = useI18n()

const meetingFormat = ref<'gmeet' | 'phone'>('gmeet')

const startDate = computed(() =>
  selectedDate.value ? new Date(Number(selectedDate.value)) : undefined,
)
const timeFormatter = computed(
  () =>
    new Intl.DateTimeFormat(locale.value, {
      timeStyle: 'short',
      timeZone: timezone.value,
    }),
)
const timezoneOptions = computed(() =>
  Intl.supportedValuesOf('timeZone').map((value) => {
    const offset = new Intl.DateTimeFormat(locale.value, {
      timeZoneName: 'shortOffset',
      timeZone: value,
    })
      .formatToParts()
      .find((part) => part.type === 'timeZoneName')?.value
    return {
      value,
      label: value.concat(offset ? ` (${offset})` : ''),
    }
  }),
)

const selectFormat = (format: string) => {
  if (format === 'gmeet' || format === 'phone') {
    meetingFormat.value = format
  }
}
const selectDate = (value: Date) => {
  if (!value || typeof value.getFullYear !== 'function') {
    selectedDate.value = ''
    return
  }
  const ts = new Date(value.getFullYear(), value.getMonth(), value.getDate())
    .getTime()
    .toString()
  const date = Object.keys(availabilities).find((d) => d === ts)
  if (date) {
    selectedDate.value = date
    if (isMobile.value) emits('change-step', 1.1)
  }
}
const selectTime = (timeslot: number) => {
  if (timeslot === startTime.value) {
    startTime.value = 0
  } else {
    startTime.value = timeslot
  }
}
</script>

<template>
  <div class="flex size-full">
    <!-- Date -->
    <div
      v-show="isMobile ? step === 1 : true"
      class="flex size-full flex-col justify-between gap-4 sm:w-1/2"
    >
      <DatePicker
        borderless
        color="yellow"
        expanded
        is-required
        mode="date"
        :disabled-dates="disabledDates.map((v) => new Date(v))"
        :locale="{ id: locale, masks: { weekdays: 'WWW' } }"
        :min-date="new Date()"
        :max-date="maxDate"
        :model-value="startDate"
        :timezone="timezone"
        @update:model-value="selectDate($event)"
        @did-move="$emit('change-page', $event[0])"
      />

      <BaseButton
        v-if="isMobile && selectedDate"
        class="mb-8 w-fit self-center"
        @click="$emit('change-step', 1.1)"
      >
        {{ $t('global.next') }}
      </BaseButton>
    </div>

    <!-- Time/Format -->
    <div
      v-show="isMobile ? step === 1.1 : true"
      class="flex w-full grow flex-col gap-6 sm:mr-4 sm:w-1/2 sm:pl-8 lg:mr-8"
    >
      <div class="flex">
        <!-- Duration -->
        <div
          v-if="availableDurations?.length"
          class="mr-4 flex w-1/2 flex-col gap-2 border-r border-r-gray-200 sm:w-1/3"
        >
          <p class="m-0 font-bold">{{ t('duration') }}</p>
          <div class="flex items-center gap-2">
            <BaseIcon name="simpleClock" :size="1.25" />
            <p class="m-0 flex gap-1 text-md">
              <select
                v-if="availableDurations.length > 1"
                class="cursor-pointer"
                @change="
                  duration = Number(($event.target as HTMLSelectElement).value)
                "
              >
                <option
                  v-for="dur in availableDurations"
                  :key="dur"
                  :value="dur"
                >
                  {{ dur / 60000 }} min
                </option>
              </select>
              <template v-else>
                {{ availableDurations[0] / 60000 }} min
              </template>
            </p>
          </div>
        </div>
        <!-- Format -->
        <div class="flex w-1/2 flex-col gap-2 sm:w-2/3">
          <p class="m-0 font-bold">{{ t('format.label') }}</p>
          <div class="flex items-center gap-2">
            <BaseIcon :name="meetingFormat" :size="1.25" />
            <select
              class="cursor-pointer text-md text-primary-800"
              :value="meetingFormat"
              @change="selectFormat(($event.target as HTMLSelectElement).value)"
            >
              <option value="phone">{{ t('format.phone') }}</option>
              <option value="gmeet">{{ t('format.gmeet') }}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Time -->
      <div class="flex grow flex-col gap-2">
        <p class="m-0 font-bold">{{ t('hour.label') }}</p>
        <div class="mb-2 flex items-center gap-2">
          <BaseIcon name="timezone" :size="1.25" />
          <BaseFormComponentsBaseMultiselect
            class="text-md text-primary-800"
            wrapper-class="w-full"
            :can-clear="false"
            :can-deselect="false"
            :model-value="timezone"
            :options="timezoneOptions"
            name="timezone"
            searchable
            @select="timezone = $event"
          />
        </div>

        <ul class="list-style-none m-0 max-h-72 w-full overflow-scroll pb-6">
          <li
            v-for="timeslot in availabilities[Number(selectedDate)]"
            :key="timeslot"
            :class="[
              'mb-2 w-full cursor-pointer rounded border border-gray-200 p-2 text-center text-primary-800',
              {
                'bg-primary-800 font-bold text-white': timeslot === startTime,
              },
            ]"
            @click="selectTime(timeslot)"
          >
            {{ timeFormatter.format(timeslot) }}
          </li>
        </ul>
      </div>

      <BaseButton
        v-if="isMobile && startTime"
        class="mb-8 w-fit self-center"
        @click="$emit('change-step', 2)"
      >
        {{ $t('global.next') }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>
:deep(.multiselect-option) {
  @apply text-gray-700;
}
:deep(.vc-container) {
  @apply h-fit sm:h-full;
}
</style>

<i18n lang="yaml">
fr:
  duration: Durée
  format:
    label: Quel format ?
    phone: Appel téléphonique
    gmeet: Google Meet
  hour:
    label: Quelle heure ?
en:
  duration: Duration
  format:
    label: Which format?
    phone: Phone call
    gmeet: Google Meet
  hour:
    label: What time?
</i18n>
