<script setup lang="ts">
import { currencies } from '@/helpers/priceHelper'

import type { Locale, SupportedCurrencies } from 'lc-services/types'

const { locale } = useI18n()
const route = useRoute()
const { getNewUrl } = useSetRouteParams()
const { currency, setCurrency } = useUser()

withDefaults(
  defineProps<{
    hideValidateButton?: boolean
  }>(),
  {
    hideValidateButton: false,
  },
)
const emits = defineEmits<{
  validated: []
}>()

const langagues: { code: Locale; name: string }[] = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
]

const newLangUrl = ref('')
const selectedLanguage = ref('')
const selectedCurrency = ref<SupportedCurrencies | ''>('')

watch(selectedCurrency, (value) => {
  const query = route.query

  if (query.currency && newLangUrl.value && value) {
    newLangUrl.value = newLangUrl.value.replace(
      /(currency=)[A-Z]{3}/,
      `$1${value}`,
    )
  }
})

const handleValidate = () => {
  if (selectedCurrency.value) {
    setCurrency(selectedCurrency.value)
  }

  if (selectedLanguage.value) {
    window.location.href = newLangUrl.value
  }

  reset()
  emits('validated')
}

const isCurrencySelected = (code: string) =>
  (code === currency.value && !selectedCurrency.value) ||
  code === selectedCurrency.value

const isLangSelected = (code: string) =>
  (code === locale.value && !selectedLanguage.value) ||
  code === selectedLanguage.value

const reset = () => {
  selectedLanguage.value = ''
  selectedCurrency.value = ''
}

const switchLang = (code: Locale) => {
  selectedLanguage.value = code
  newLangUrl.value = getNewUrl(code)
}

const switchCurrency = (code: SupportedCurrencies) => {
  selectedCurrency.value = code
}

defineExpose({ handleValidate })
</script>

<template>
  <div class="mb-9">
    <div class="mb-3.5 text-md font-medium">
      {{ $t('header.language') }}
    </div>
    <div class="flex items-center">
      <div
        v-for="(language, i) in langagues"
        :key="language.code"
        class="flex items-center"
      >
        <button
          type="button"
          :class="[
            'cursor-pointer text-md',
            {
              'text-gray-500': !isLangSelected(language.code),
            },
          ]"
          @click="switchLang(language.code)"
        >
          {{ language.name }}
        </button>
        <span
          v-if="i < langagues.length - 1"
          class="mx-5 h-3.5 w-px bg-gray-200"
        />
      </div>
    </div>
  </div>
  <div class="mb-9">
    <div class="mb-3.5 text-md font-medium">
      {{ $t('header.currency') }}
    </div>
    <div class="flex items-center">
      <div
        v-for="(currencyItem, i) in currencies"
        :key="currencyItem.code"
        class="flex items-center"
      >
        <button
          type="button"
          :class="[
            'text-md',
            {
              'text-gray-500': !isCurrencySelected(currencyItem.code),
            },
          ]"
          @click="switchCurrency(currencyItem.code)"
        >
          {{ currencyItem.name }}
        </button>
        <span
          v-if="i < currencies.length - 1"
          class="mx-5 h-3.5 w-px bg-gray-200"
        />
      </div>
    </div>
  </div>
  <BaseButton
    v-if="!hideValidateButton"
    color="secondary"
    block-full
    @click="handleValidate"
  >
    {{ $t('global.validate') }}
  </BaseButton>
</template>
