import { getWindowWidth, type BreakpointSize } from '~/helpers/windowWidth'

export const useBreakpoint = () => {
  // Default breackpoint size
  const { isDesktop: isDesktopDevice, isTablet: isTabletDevice } = useDevice()
  const device = isDesktopDevice
    ? 'desktop'
    : isTabletDevice
      ? 'tablet'
      : 'mobile'

  const breakpointSize = useState<BreakpointSize>('breakpoint-size', () => {
    return getWindowWidth() || device
  })

  // Screen sizes
  const isSmallScreen = computed(() =>
    ['mobile', 'tablet'].includes(breakpointSize.value),
  )
  const isMediumScreen = computed(() =>
    ['tablet', 'laptop'].includes(breakpointSize.value),
  )
  const isSmallAndMediumScreen = computed(() =>
    ['mobile', 'tablet', 'laptop'].includes(breakpointSize.value),
  )
  const isLargeScreen = computed(() =>
    ['desktop'].includes(breakpointSize.value),
  )
  const isBigScreen = computed(() => breakpointSize.value === '2xl')

  // Devices
  const isMobile = computed(() => ['mobile'].includes(breakpointSize.value))
  const isTablet = computed(() => ['tablet'].includes(breakpointSize.value))
  const isDesktop = computed(() =>
    ['laptop', 'desktop', '2xl'].includes(breakpointSize.value),
  )
  const isDesktopOrTablet = computed(() => isTablet.value || isDesktop.value)

  const resizeTimeout = ref<ReturnType<typeof setTimeout>>()
  // Trigger Window
  const windowSizeChanged = () => {
    if (import.meta.server) return
    resizeTimeout.value = setTimeout(() => {
      breakpointSize.value = getWindowWidth()
    }, 100)
  }

  onMounted(() => {
    const windowWidth = getWindowWidth()
    if (breakpointSize.value !== windowWidth) breakpointSize.value = windowWidth
  })
  onUnmounted(() => clearTimeout(resizeTimeout.value))

  return {
    isBigScreen,
    isDesktop,
    isDesktopOrTablet,
    isLargeScreen,
    isMediumScreen,
    isMobile,
    isSmallAndMediumScreen,
    isSmallScreen,
    isTablet,
    windowSizeChanged,
  }
}
