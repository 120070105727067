<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { authenticated } = useAuth()
const { headerTheme } = useHeaderNavigation()
const { locale } = useI18n<unknown, Locale>()
const { properties } = useUserHouses()
const { user } = useUser()
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { setSummaryOpen, summaryOpen } = useMultipleWishlistSummary()

const transparentColor = computed(() => headerTheme.value === 'transparent')
const initial = computed(
  () => `${user.value.firstName?.charAt(0)}${user.value.lastName?.charAt(0)}`,
)

const accountLink = computed(() => {
  const accountLoggedLink = properties.value.length
    ? localePath({
        name: 'myaccount-property-name-rentals',
        params: { name: properties.value[0].slug[locale.value] },
      })
    : localePath('myaccount-bookings')

  return authenticated.value ? accountLoggedLink : localePath('login')
})

const handleTrack = (connectionType: string) => {
  trackEvent({
    event: 'header_connection_click',
    connection_type: connectionType,
  })
}

const handleMouseoverPhone = () => {
  if (summaryOpen) setSummaryOpen(false)
}
</script>

<template>
  <div v-if="!authenticated" class="group relative">
    <button
      type="button"
      class="relative block"
      @mouseover="handleMouseoverPhone"
    >
      <BaseIcon
        name="profileCircle"
        :class="[
          'icon-transition stroke-1 group-hover:stroke-[1.5]',
          transparentColor ? 'text-white' : 'text-gray-700',
        ]"
      />

      <span
        :class="[
          'absolute -bottom-2 hidden h-1 w-6 border-b group-hover:block',
          transparentColor ? 'border-white' : 'border-gray-700',
        ]"
      />
    </button>

    <div class="absolute right-0 z-fixed hidden group-hover:block">
      <div
        :class="[
          'mt-5 flex w-[246px] flex-col gap-6 rounded p-6 shadow-normal ',
          { 'bg-gray-700/85': transparentColor },
          { 'bg-white': !transparentColor },
        ]"
      >
        <NuxtLink
          :class="[
            'flex items-center gap-2 text-md',
            { 'text-white': transparentColor },
            { 'text-gray-700': !transparentColor },
          ]"
          :to="localePath('login')"
          @click.prevent="handleTrack('Client or owner')"
        >
          <BaseIcon
            name="user"
            :class="[
              'icon-transition',
              transparentColor ? 'text-white' : 'text-gray-700',
            ]"
            :stroke-width="1"
          />
          {{ $t('header.loginOrSignup') }}
        </NuxtLink>
        <div class="border-t" />
        <NuxtLink
          :class="[
            'flex items-center gap-2 text-md',
            { 'text-white': transparentColor },
            { 'text-gray-700': !transparentColor },
          ]"
          :to="localePath('login')"
          @click.prevent="handleTrack('Travel agent')"
        >
          <BaseIcon
            name="largeSuitcase"
            :class="[
              'icon-transition',
              transparentColor ? 'text-white' : 'text-gray-700',
            ]"
            :stroke-width="1"
          />
          {{ $t('header.travelAgent') }}
        </NuxtLink>
      </div>
    </div>
  </div>
  <NuxtLink
    v-else
    :class="[
      'flex size-8 items-center justify-center rounded-full text-xs font-semibold uppercase !no-underline',
      {
        'bg-gray-100 text-secondary-400': !transparentColor,
        'bg-white/20 text-white': transparentColor,
      },
    ]"
    :to="accountLink"
  >
    {{ initial }}
  </NuxtLink>
</template>
