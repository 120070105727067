import { TokenAuthRepository } from "./interfaces/TokenAuthRepository";
import { TokenAuthPresentation } from "./interfaces/TokenAuthPresentation";
import { dispatch } from "../../domain";
import { SignInTokenInput } from "./types";
import Formatter from "../../../../services/Formatter";

export default class TokenAuthUseCase extends Formatter {
  constructor(private repository: TokenAuthRepository) {
    super();
  }

  async execute(presenter: TokenAuthPresentation, input: SignInTokenInput) {
    presenter.displayAuthLoading();
    if (input?.email) {
      const emailFormatted = this.emailFormatter(input.email);
      if (input?.emailToken) {
        const signUpTokenData = {
          user: {
            email: emailFormatted,
            signup_token: input.emailToken,
          },
        };
        const tokenForSignUpResponse =
          await this.repository.tokenSignUp(signUpTokenData);
        dispatch.emailTokenSignUp(tokenForSignUpResponse, presenter);
      } else if (input?.loginToken) {
        const signInEmail = {
          user: {
            login_token: input.loginToken,
            email: emailFormatted,
          },
        };
        const emailResponse = await this.repository.tokenSignIn(signInEmail);
        dispatch.emailTokenSignIn(emailResponse, presenter, input.redirect);
      }
    }
  }
}
